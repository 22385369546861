import React, { useState } from 'react'
import { isSubAdminPermission, isUserPermission } from "../../../common/helper/permission";
import { useNavigate } from 'react-router-dom';
import { isPermission } from '../../../common/helper/PermissionManager';
import { useDispatch } from 'react-redux';
import { addNewBreadcrumbs } from '../../../store/slices/breadcrumbsSlice';
function SupportSymbol({

    newSupportUrl,
    stateData

}) {
    const url = newSupportUrl
    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const handleNewDispatch = (url1) => {
        const BreadCrumbsObject = {
            title: "",
            // type: "site",
            // tab: "CONTACT_LISTING",
        };
        BreadCrumbsObject.title = "New Support Form";
        dispatch(
            addNewBreadcrumbs({
                ...BreadCrumbsObject,
                url: "account/new-support",
            })
        );
    };
    const supportRedirect = () => {
        handleNewDispatch(url)
        navigate(url, { state: stateData });
    };

    const documentRedirect = () => {
        // navigate("/account-document-upload", {
        //     state: stateData
        // });
    };
    const notesRedirect = () => {
        // navigate(`/account/new-note?account_id=${accountId}`);
    };
    const handleHoverFloating = () => {
        setIsOpen(true);
    };

    const handleLeaveFloating = () => {
        setIsOpen(false);
    };
    return (
        <div>
            <div
                className="floating-menu-btn d-flex flex-column gap-2"
                onMouseEnter={handleHoverFloating}
                onMouseLeave={handleLeaveFloating}
            >
                {isOpen && (
                    <>
                        {/* {(isSubAdminPermission("new-document") === 1 ||
                            isUserPermission("new-document") === 1) && (
                                <img
                                    src="/assets/images/NewDocument.svg"
                                    width={60}
                                    height={60}
                                    style={{
                                        padding: "2px",
                                        borderRadius: "50%",
                                        borderColor: "#0c71c3",
                                        borderWidth: "3px",
                                        borderStyle: "solid",
                                    }}
                                    className="pointer bg-white"
                                    onClick={documentRedirect}
                                    title="New Document"
                                />
                            )}*/}

                        {/* {(isSubAdminPermission("new-support") === 1 ||
                            isUserPermission("new-support") === 1) && ( */}
                        {Number(isPermission({ type: "support", name: "newSupport" })) === 1 ? (
                            <img
                                src="/assets/images/NewSupport.svg"
                                width={60}
                                height={60}
                                style={{
                                    padding: "2px",
                                    borderRadius: "50%",
                                    borderColor: "#0c71c3",
                                    borderWidth: "3px",
                                    borderStyle: "solid",
                                }}
                                className="pointer bg-white"
                                onClick={() => { supportRedirect() }}
                                title="New Support"
                            />
                        ) : ("")}
                        {/* )} */}

                        {/*     {(isSubAdminPermission("new-note") === 1 ||
                            isUserPermission("new-note") === 1) && (
                                <img
                                    src="/assets/images/NewNote.svg"
                                    width={60}
                                    height={60}
                                    style={{
                                        padding: "2px",
                                        borderRadius: "50%",
                                        borderColor: "#0c71c3",
                                        borderWidth: "3px",
                                        borderStyle: "solid",
                                    }}
                                    className="pointer bg-white"
                                    onClick={notesRedirect}
                                    title="New Note"
                                />
                            )}*/}
                    </>
                )}

                <img
                    src="/assets/images/Plus.svg"
                    width={60}
                    height={60}
                    style={{
                        padding: "2px",
                        borderRadius: "50%",
                        borderColor: "#0c71c3",
                        borderWidth: "3px",
                        borderStyle: "solid",
                    }}
                    className="pointer bg-white"
                    // onMouseLeave={() => {
                    //   setIsOpen((prev) => !prev);
                    // }}
                    onClick={() => {
                        setIsOpen((prev) => !prev);
                    }}
                />
            </div>
        </div>
    )
}

export default SupportSymbol
