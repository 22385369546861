import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { CallGETAPI } from "../../../common/services";
import { DecryptToken, getPermission } from "../../../common/helper";
import SubHeading from "../components/header/SubHeading";
import ChecksNeeded from "../components/cardsComponents/ChecksNeeded";
import Equipment from "../components/cardsComponents/Equipment";
import EquipmentAlerts from "../components/cardsComponents/EquipmentAlerts";
import Aeds from "../components/cardsComponents/Aeds";
import AedsDistribution from "../components/cardsComponents/AedsDistribution";
import { isPermission } from "../../../common/helper/PermissionManager";
import { handleAccount } from "../../../common/helper/permission";
import { useDispatch, useSelector } from "react-redux";
import { clearBreadcrumbs } from "../../../store/slices/breadcrumbsSlice";
// import { user } from "../../../common/helper/BasicFn";

const UserDashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [isUser, setisUser] = useState("");
  const [tabs, setTabs] = useState([]);
  const permissions = getPermission()?.split(",") || [];
  const [permissionsArr, setPermissionsArr] = useState(permissions);
  const [isDash, setIsDash] = useState(permissions.includes("dashboard"));
  const [cardChunks, setCardChunks] = useState([]);
  const userData = DecryptToken();
  const user = DecryptToken();
  const userPermission = [
    "accounts-listing",
    "account-details",
    "account-edit",
    "site-tab",
    "sites-new",
    "site-details",
    "site-details-edit",
    "contact-tab",
    "contacts-new",
    "contact-details",
    "contact-details-edit",
    "equipment-tab",
    "new-aed",
    "aed-details",
    "aed-edit",
    "move-aed",
    "assign-aed",
    "new-accessories",
    "move-accessory",
    "new-aed-checks",
    "aed-check-details",
    "aed-service-check",
    "service-check-details",
    "training-tab",
    "training-new",
    "training-details",
    "edit-training",
    "inperson-tab",
    "new-inperson",
    "inperson-details",
    "edit-inperson",
    "pops-tab",
    "new-pop",
    "pop-details",
    "pop-edit",
    "notes-tab",
    "new-note",
    "note-details",
    "edit-note",
    "email-tab",
    "support-tab",
    "new-support",
    "support-details",
    "edit-support",
    "documents-tab",
    "new-document",
    "document-details",
    "edit-document",
    "rfi-tab",
    "new-rfi",
    "rfi-details",
    "edit-rfi",
    "out-of-service",
    "support-respond",
    "support-reassign",
    "support-close",
    "pop-clone",
    "inperson-clone",
    "inperson-student-tab",
    "inperson-certification-tab",
    "inperson-instructor",
    "inperson-broadcast",
  ];

  const fetchLoad = async () => {
    let result1 = await CallGETAPI("user/user-permissions");
    const isUser = result1?.data.isTraining;
    setisUser(isUser);
    // setLoading(false);
  };
  useEffect(() => {
    // Dispatch clearBreadcrumbs action when component is mounted
    dispatch(clearBreadcrumbs());
  }, [dispatch]);
  useEffect(() => {
    // fetchLoad();
  }, [isUser]);

  const handleCardClick = (selectedTab) => {
    navigate(`/user/${selectedTab}/${userData?.account_id}`, {
      state: { userData: userData, tabs: tabs, selectedTab: selectedTab },
    });
  };

  var staticCardData = [
    {
      title: "Checks Needed",
      bg: "#315A05",
      color: "white",
      img: "/assets/images/check.png",
      footer: "Equipment Checks",
      width: 60,
      height: "auto",
      link: "Equipment",
      id: "equipment-tab",
      key: "Checks Needed"
    },
    {
      title: "Equipment",
      bg: "#8D181B",
      color: "white",
      width: 60,
      height: "auto",
      img: "/assets/images/equipment.png",
      footer: "Equipment",
      link: "Equipment",
      id: "equipment-tab",
      key: "Equipment"
    },
    {
      title: "Support",
      bg: "#BF7609",
      color: "white",
      img: "/assets/images/support.png",
      footer: "Supports",
      width: 60,
      height: "auto",
      link: "Support",
      id: "support-tab",
      key: "Support"
    },
    {
      title: "Equipment Alerts",
      bg: "#0C71C3",
      color: "white",
      bg: "linear-gradient(to top right, #FFE800 50%, #E40000 50%)",
      img: "/assets/images/warning.png",
      footer: "Alerts",
      width: 60,
      height: "auto",
      link: "Equipment",
      id: "equipment-tab",
      key: "Equipment Alerts"
    },
    {
      title: "AEDs",
      bg: "#999999",
      color: "white",
      img: "/assets/images/pie-chart-2.png",
      width: 110,
      height: 110,
      link: "Equipment",
      id: "equipment-tab",
      key: "AEDs"
    },
    {
      title: "AEDs Distribution",
      bg: "black",
      color: "white",
      img: "/assets/images/pie-chart-1.png",
      width: 110,
      height: 110,
      link: "Equipment",
      id: "equipment-tab",
      key: "AEDs Distribution"
    },
    {
      title: "Training Distribution",
      bg: "#E4E4E4",
      color: "black",
      img: "/assets/images/pie-chart-3.png",
      width: 110,
      height: 110,
      link: "Training",
      id: "training-tab",
      key: "Training Distribution"
    },
    {
      title: "Training",
      bg: "#784AFF",
      color: "white",
      img: "/assets/images/sheild.png",
      footer: "Equipment Checks",
      width: 60,
      height: "auto",
      link: "Training",
      id: "training-tab",
      key: "Training"
    },
    {
      title: "Classes Scheduled",
      bg: "#ACE997",
      color: "black",
      img: "/assets/images/calendar.png",
      footer: "Inperson Classes",
      width: 60,
      height: "auto",
      link: "Inperson",
      id: "inperson-tab",
      key: "Classes Scheduled"
    },
    {
      title: "Seats Remain",
      bg: "#5CB200",
      color: "white",
      img: "/assets/images/calendar-alt.png",
      footer: "Assign",
      width: 60,
      height: "auto",
      link: "Inperson",
      id: "inperson-tab",
      key: "Seats Remain"
    },
  ]

  const filteredStaticCardData = staticCardData?.filter((item) =>
    permissions?.includes(item.id)
  );

  const chunkArray = (array, chunkSizes) => {
    const chunks = [];
    let startIndex = 0;

    chunkSizes.forEach((chunkSize) => {
      chunks.push(array.slice(startIndex, startIndex + chunkSize));
      startIndex += chunkSize;
    });

    return chunks;
  };

  const cardsChunks = chunkArray(staticCardData, [4, 3, 4]);
  const [isCondition, setIsCondition] = useState(true)
  useEffect(() => {
    if (cardsChunks) {
      setCardChunks(cardsChunks);
      let condition = 1;
      cardsChunks.map((it) => {
        it.forEach((card, cardIndex) => {
          if (
            Number(isPermission({ type: "card", name: card.key }))
          ) {
            console.log({ fetchCondition: Number(isPermission({ type: "card", name: card.key })) })
            condition = 0;
          }
        })
      })
      setIsCondition(condition)
    }
  }, []);
  const accountdetail = useSelector((state) => state.AccountData.data.accountdetail);
  const Is_AccountList = useSelector((state) => state.AccountData.data.Is_AccountList);
  const deniedDivStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    flexDirection: "column",
  };

  const handleBack = () => {
    navigate(-1);
  };

  const token = DecryptToken();
  const accountId = token?.account_id;
  const permission = getPermission();

  //   const handleAccount = async () => {
  //     try {
  //       const accountsData = await CallGETAPI("user/user-account-list-v1");
  //       const multipleAccount =
  //         accountsData?.data?.data?.accountlist.length > 0 ? true : false;
  //       if (multipleAccount) {
  //         navigate("/user-listing/account");
  //       } else {
  //         permission.includes("account-details")
  //           ? navigate("/user/Details/" + accountId)
  //           : permission.includes("site-tab")
  //           ? navigate("/user/Sites/" + accountId)
  //           : permission.includes("contact-tab")
  //           ? navigate("/user/Contacts/" + accountId)
  //           : permission.includes("equipment-tab")
  //           ? navigate("/user/Equipment/" + accountId)
  //           : permission.includes("notes-tab")
  //           ? navigate("/user/Notes/" + accountId)
  //           : permission.includes("support-tab")
  //           ? navigate("/user/Support/" + accountId)
  //           : permission.includes("training-tab")
  //           ? navigate("/user/Training/" + accountId)
  //           : permission.includes("email-tab")
  //           ? navigate("/user/Emails/" + accountId)
  //           : permission.includes("documents-tab")
  //           ? navigate("/user/Documents/" + accountId)
  //           : permission.includes("rfi-tab")
  //           ? navigate("/user/RFI/" + accountId)
  //           : "";
  //       }
  //     } catch (error) {
  //       console.error("Error fetching accounts data:", error);
  //     }
  //   };

  return (

    <>

      {/* <div> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "1%",
          width: "100%",
          height: "80vh",
          paddingInline: "",
          alignItems: "center",
          // marginBottom: "1%",
        }}
      >
        {isCondition ? (
          <>
            <SubHeading
              hideNew={true}
              hideHierarchy={true}
              title={"Dashboard"}
              // newUrl="/new-account"
              subHeading={true}
              backTab={false}
            />
            <div style={deniedDivStyle}>
              <div
                className="text-center "
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginBottom: "0px",
                  height: "60px",  // Adjust this value to increase button height
                  width: "200px",  // Adjust this value to increase button width

                }}

              >
                <button
                  className="btn btn-primary"
                  onClick={() => {
                    if (Is_AccountList === true) {
                      navigate("/accounts-listing")
                    }
                    else {
                      navigate(`/account-details/${accountdetail.account_id}`)
                    }
                  }}
                >
                  Go to Account
                </button>

              </div>
            </div>
          </>
        ) : (
          <>
            {/* {Number(isPermission({ type : "card" })) === 1 ? ( */}
            {cardChunks?.map((chunk, rowIndex) => (
              <>
                <div
                  key={rowIndex}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "3px 0",
                  }}
                >
                  {chunk.map((card, cardIndex) => (
                    <>
                      {Number(isPermission({ type: "card", name: card.key })) === 1 ? (
                        <div
                          key={cardIndex}
                          style={{
                            ...cardStyle,
                            background: card.bg,
                            width:
                              rowIndex === 1 &&
                                (cardIndex === 0 ||
                                  cardIndex === 1 ||
                                  cardIndex === 2)
                                ? 420
                                : 310,
                            padding:
                              rowIndex === 1 &&
                                (cardIndex === 0 ||
                                  cardIndex === 1 ||
                                  cardIndex === 2)
                                ? "1% 0"
                                : "0 0 1%",
                            height:
                              rowIndex === 1 &&
                                (cardIndex === 0 ||
                                  cardIndex === 1 ||
                                  cardIndex === 2)
                                ? 165
                                : 165,
                          }}
                        // onClick={() => handleCardClick(card.link)}
                        >
                          <h1
                            className="Text"
                            style={{ ...titleStyle, color: card.color }}
                          >
                            {card.title}
                          </h1>
                          <Grid container spacing={0}>
                            <Grid
                              item
                              xs={4}
                              style={{ color: "white", display: "flex", justifyContent: "center", marginLeft: "15px" }}
                            >
                              <img
                                src={card?.img}
                                style={{
                                  ...imageStyle,
                                  width: card?.width,
                                  height: card?.height,
                                  marginLeft: "30px"
                                }}
                                alt={card.title}
                              />
                            </Grid>

                            <Grid
                              item
                              xs={6}
                              style={{
                                color: "white",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                color: card.color,
                              }}
                            >
                              {card.title == "Checks Needed" ? (
                                <>
                                  <ChecksNeeded />
                                </>
                              ) : (
                                ""
                              )}

                              {card.title == "Equipment" ? (
                                <>
                                  <Equipment />
                                </>
                              ) : (
                                ""
                              )}

                              {card.title == "Equipment Alerts" ? (
                                <>
                                  <EquipmentAlerts />
                                </>
                              ) : (
                                ""
                              )}

                              {card.title == "AEDs" ? (
                                <>
                                  <Aeds />
                                </>
                              ) : (
                                ""
                              )}

                              {card.title == "AEDs Distribution" ? (
                                <>
                                  <AedsDistribution />
                                </>
                              ) : (
                                ""
                              )}
                            </Grid>
                          </Grid>

                          {card?.footer ? (
                            <>
                              <Button variant="contained" style={buttonStyle}>
                                {card?.footer}
                              </Button>
                            </>
                          ) : (
                            <></>
                          )}
                        </div>
                      ) : ("")}
                    </>
                  ))}
                </div>
              </>
            ))}
          </>
        )}
      </div>
      {/* </div> */}
    </>
  );
};

const cardStyle = {
  // minWidth: '350px',
  width: "calc(20% - 20px)",
  margin: "10px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "5px",
  boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  cursor: "pointer",
  border: "2px solid #3498db"
};

const imageStyle = {
  // width: 80,
  // height: 90,
  boxShadow: "none",
};

const titleStyle = {
  fontSize: "25px",
  margin: "10px",
  color: "white",
};

const buttonStyle = {
  boxShadow: "none",
  borderRadius: 5,
  border: "1px solid white",
  marginTop: "5%",
  textTransform: "none",
};

export default UserDashboard;
