import React, { useEffect, useState } from "react";
import { CallGETAPI } from "../../../../common/services";

const Aeds = () => {
  const [aeds, setAeds] = useState();

  const fetchOnLoad = async () => {
    const response = await CallGETAPI("user/user-aed");
    setAeds(response?.data?.data);
    console.log({ response });
  };

  useEffect(() => {
    fetchOnLoad();
  }, []);

  return (
    <>
      <div>
        {aeds &&
          aeds.map((aed) => (
            <>
              <div style={{minWidth:"400px"}}>
                {aed?.aed_count || Number(aed?.aed_count) === 0
                  ? aed?.aed_count + " " + aed?.brand_model_name
                  : ""}
              </div>
            </>
          ))}
      </div>
    </>
  );
};

export default Aeds;
