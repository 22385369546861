import React, { useEffect, useState } from "react";
import { CallGETAPI } from "../../../../common/services";

const Equipment = () => {
  const [equipment, setEquipment] = useState();

  const fetchOnLoad = async () => {
    const response = await CallGETAPI("user/user-equipment");
    setEquipment(response?.data?.data);
    console.log({ response });
  };

  useEffect(() => {
    fetchOnLoad();
  }, []);

  return (
    <>
      <div style={{marginLeft:"20%"}}>
        <div>
          {" "}
          {equipment?.aeds || Number(equipment?.aeds) === 0
            ? equipment?.aeds + " " + "AEDS"
            : ""}
        </div>
        <div>
          {equipment?.equipment || Number(equipment?.equipment) === 0
            ? equipment?.equipment + " " + "Equipment"
            : ""}
        </div>
      </div>
    </>
  );
};

export default Equipment;
