import React, { useEffect, useState } from "react";
import { CallGETAPI } from "../../../../common/services";

const ChecksNeeded = () => {
  const [aedChecks, setaedChecks] = useState();

  const fetchOnLoad = async () => {
    const response = await CallGETAPI("user/user-aed-check");
    setaedChecks(response?.data?.data);
    console.log({ response });
  };

  useEffect(() => {
    fetchOnLoad();
  }, []);

  return (
    <>
      <div style={{marginLeft:"20%"}}>
        <div>
          {aedChecks?.aeds || Number(aedChecks?.aeds) === 0
            ? aedChecks?.aeds + " " + "AEDS"
            : ""}
        </div>
        <div>
          {aedChecks?.equipment || Number(aedChecks?.equipment) === 0
            ? aedChecks?.equipment + " " + "Equipment"
            : ""}
        </div>
      </div>
    </>
  );
};

export default ChecksNeeded;
