import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ChevronRight } from "lucide-react";
import { handleBreadcrumbsClick, updateBreadcrumbs } from "../../../store/slices/breadcrumbsSlice";
import { useNavigate, useLocation } from "react-router-dom";
import { handleTabfunction, selecteTab } from "../../../store/slices/TabSlice";

const FinalNewBreadcrumbs = () => {
    const dispatch = useDispatch();
    const breadcrumbsFromStore = useSelector((state) => state.BreadCrumbs.breadcrumbs);
    const navigate = useNavigate();
    const location = useLocation();

    const [currentUrl, setCurrentUrl] = useState(location.pathname);

    useEffect(() => {
        setCurrentUrl(location.pathname);


        if (breadcrumbsFromStore && breadcrumbsFromStore.length === 1 && breadcrumbsFromStore[0].url === location.pathname) {
            dispatch(updateBreadcrumbs([]));
        }
    }, [location.pathname, breadcrumbsFromStore, dispatch]);





    const handleDispatch = (url, index) => {


        if (breadcrumbsFromStore && index === breadcrumbsFromStore.length - 1) return;

        if (url !== currentUrl) {
            setCurrentUrl(url);

            const updatedBreadcrumbs = breadcrumbsFromStore.slice(0, index + 1);
            dispatch(updateBreadcrumbs(updatedBreadcrumbs));

            const breadcrumbTitle = breadcrumbsFromStore[index].title;
            const breadcrumbTab = breadcrumbsFromStore[index].tab;  // SITE_DETAILS
            const breadcrumbType = breadcrumbsFromStore[index].type; // site

            if (breadcrumbTab && breadcrumbType) {
                dispatch(handleTabfunction({ value: breadcrumbTab, type: breadcrumbType }));
            }


            const accountId = breadcrumbsFromStore?.[index]?.accountId || "";  // SITE_DETAILS
            const accountName = breadcrumbsFromStore?.[index]?.accountName || ""; // site

            const locationOjb = {};
            if (accountId && accountName) {
                locationOjb.accountId = accountId;
                locationOjb.accountName = accountName;
            }


            navigate(url, { replace: true, state: locationOjb });
        }
    };

    return (
        <nav className="flex items-center p-4" style={{ marginLeft: "-32px", marginBottom: "-36px" }}>
            {breadcrumbsFromStore && breadcrumbsFromStore.map((breadcrumb, index) => (
                index >= breadcrumbsFromStore.length - 3 && ( // Only render the last three items
                    <React.Fragment key={breadcrumb.url || index}>

                        {breadcrumbsFromStore.length > 1 &&
                            ((breadcrumbsFromStore.length - 1 === 0 ||
                                (breadcrumbsFromStore.length > 2 && index === breadcrumbsFromStore.length - 2) ||
                                index === breadcrumbsFromStore.length - 1) && (
                                    <ChevronRight className="w-4 h-4 mx-2 text-gray-400" />
                                ))
                        }

                        <span
                            onClick={() => {

                                handleDispatch(breadcrumb.url, index);
                            }}
                            style={index === breadcrumbsFromStore.length - 1 ? {} : { color: "#0c71c3" }}
                            className={`flex items-center text-sm group transition-all duration-300 ease-in-out ${index === breadcrumbsFromStore.length - 1
                                ? "text-gray-600 cursor-not-allowed"
                                : breadcrumb.url === currentUrl
                                    ? "text-gray-600 cursor-not-allowed"
                                    : "hover:text-[#0a5ca0] cursor-pointer"
                                }`}
                        >
                            {breadcrumb.icon && (
                                <span className="mr-1.5 group-hover:text-[#0a5ca0] transition-colors duration-300">
                                    {breadcrumb.icon}
                                </span>
                            )}

                            <span className="relative">
                                {breadcrumb.title}
                                {breadcrumb.url !== currentUrl && (
                                    <span className="absolute bottom-0 left-0 w-0 h-0.5 group-hover:w-full transition-all duration-300 ease-in-out"></span>
                                )}
                            </span>
                        </span>
                    </React.Fragment>
                )
            ))}
        </nav>

    );
};

export default FinalNewBreadcrumbs;