import {
  faCaretDown,
  faChartBar,
  faChartLine,
  faCog,
  faEnvelope,
  faHome,
  faListAlt,
  faSignOutAlt,
  faUserPlus,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Nav, Offcanvas } from "react-bootstrap";
import { HandleLogout } from "../../helper/BasicFn";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { DecryptToken } from "../../helper";
import { isPermission } from "../../helper/PermissionManager";
import { useDispatch, useSelector } from "react-redux";
import { addNewBreadcrumbs } from "../../../store/slices/breadcrumbsSlice";
import { handleTabfunction } from "../../../store/slices/TabSlice";
import { AccountDetailsTab } from "../../hooks";

// Custom Dropdown component for sidebar
const SidebarDropdown = ({ title, icon, children, isOpen, onToggle }) => {
  return (
    <Nav.Item>
      <Nav.Link onClick={onToggle} className="sidebar-dropdown-toggle">
        <FontAwesomeIcon icon={icon} className="me-2" />
        {title} &nbsp;{" "}
        <FontAwesomeIcon
          icon={faCaretDown}
          className={`ms-auto ${isOpen ? "rotate-icon" : ""}`}
        />
      </Nav.Link>
      <div
        className={`sidebar-dropdown   ${isOpen ? "show" : "none"}   `}
        style={{ display: isOpen ? "block" : "none" }}
      >
        <Nav className="flex-column">{children}</Nav>
      </div>
    </Nav.Item>
  );
};

const UserSidebar = ({ show, handleClose }) => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const user = DecryptToken();

  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prevState) =>
      prevState === dropdownName ? null : dropdownName
    );
  };

  const navigate = useNavigate();
  const handleLogoutClick = async () => {
    await HandleLogout();
  };
  const location = useLocation();

  const dispatch = useDispatch();
  const BreadCrumbsObject = {
    title: "",
    tab: "",
    type: "",
  };
  const breadcrumbsFromStore = useSelector(
    (state) => state.BreadCrumbs.breadcrumbs
  );
  console.log({ breadcrumbsFromStore });
  const handleDetailsDispatch = (accountId, account_name) => {
    BreadCrumbsObject.title = account_name + " Details";
    BreadCrumbsObject.tab = "Details";
    BreadCrumbsObject.type = "account";
    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account-details/${accountId}?tab=Details`,
      })
    );
  };
  const accountdetail = useSelector(
    (state) => state.AccountData.data.accountdetail
  );
  const Is_AccountList = useSelector(
    (state) => state.AccountData.data.Is_AccountList
  );
  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        style={{ width: "300px", background: "#000" }}
        id="sidebar-menus"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="text-white">Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Link to="/user-dashboard" className="nav-link">
              Dashboard
            </Link>

            {Number(isPermission({ type: "btn", name: "accountName" })) === 1 ||
            Number(isPermission({ type: "btn", name: "accountListing" })) ===
              1 ? (
              <span
                onClick={() => {
                  if (Is_AccountList === true) {
                    navigate("/accounts-listing");
                  } else {
                    handleDetailsDispatch(
                      user?.account_id,
                      accountdetail.account_name
                    );
                    navigate(`/account-details/${user?.account_id}`);
                    dispatch(
                      handleTabfunction({
                        value: AccountDetailsTab.DETAILS,
                        type: "account",
                      })
                    );
                  }
                }}
                className="nav-link"
                style={{
                  cursor:
                    Number(
                      isPermission({ type: "btn", name: "accountName" })
                    ) === 1 ||
                    Number(
                      isPermission({ type: "btn", name: "accountListing" })
                    ) === 1
                      ? "pointer"
                      : "default",
                }}
              >
                Accounts
              </span>
            ) : (
              ""
            )}

            {Number(isPermission({ type: "btn", name: "siteListing" })) ===
            1 ? (
              <Link to="/Admin/Sites-listing" className="nav-link">
                Site Listing
              </Link>
            ) : (
              ""
            )}

            {Number(isPermission({ type: "btn", name: "contactListing" })) ===
            1 ? (
              <Link to="/Admin/Contact-listing" className="nav-link">
                Contact Listing
              </Link>
            ) : (
              ""
            )}
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default UserSidebar;
