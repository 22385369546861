import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import MessageHandler from "../../../../common/components/MessageHandler";
import moment from "moment/moment";
import { useDispatch, useSelector } from "react-redux";
import SubHeading from "../../../dashboard/components/header/SubHeading";
import EditAedForm from "../components/EditAedForm";
import EditChargePakInfo from "../components/chargePack/EditChargePakInfo";
import EditAdultPakInfo from "../components/pad/EditAdultPakInfo";
import EditSpareAdultPadInfo from "../components/pad/EditSpareAdultPadInfo";
import EditAdultPadPakInfo from "../components/pad/EditAdultPadPakInfo";
import EditSpareAdultPadPakInfoComp from "../components/pad/EditSpareAdultPadPakInfoComp";
import EditPediatricPadInfo from "../components/pediatric/EditPediatricPadInfo";
import EditSparePediatricPadInfo from "../components/pediatric/EditSparePediatricPadInfo";
import EditPediatricPadPakInfo from "../components/pediatric/EditPediatricPadPakInfo";
import EditSparePediatricPadPakInfo from "../components/pediatric/EditSparePediatricPadPakInfo";
import EditPediatricKeyInfo from "../components/EditPediatricKeyInfo";
import EditGatewayInformation from "../components/EditGatewayInformation";
import EditBuiltInRMSInformation from "../components/EditBuiltInRMSInformation";
import {
  BatteryTypebyModel,
  checkIfAnyKeyHasValue,
  editCheckIfAnyKeyHasValue,
  editUpdateJsonArrayWithUnknown,
  EditUpdateJsonArrayWithUnknown,
  FetchAccountDetails,
  FetchAEDDetails,
  GetAccountList,
  GetAedBrands,
  GetRMSBrand,
  PadTypeByModal,
  updateJsonArrayWithUnknown,
} from "../../../../common/helper/BasicFn";
import { CallGETAPI, CallPOSTAPI } from "../../../../common/services";
import { updateAEDLoading } from "../../../../store/slices/StandloneAEDSlice";
import { HasUnknownValue } from "../../../../common/helper";
import Loading from "../../../../common/components/Loader/Loading";
import EditSpareChargePakInfo from "../components/chargePack/EditSpareChargePakInfo";
import EditParentBatteryInfo from "../components/battery/EditParentBatteryInfo";
import { AddMainRowBtn } from "../components/common/AddMainRowBtn";
import { removeBreadcrumb } from "../../../../store/slices/breadcrumbsSlice";
import { Spinner } from "react-bootstrap";

const overlay = {
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(0,0,0,0.5)", 
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  color: "white",
  zIndex: 2,
};

const sectioNameObj = {
  has_man: 0,
  has_battery: 0,
  has_10pk: 0,
  has_9v: 0,
  has_installby: 0,
  ChargePakInformation: 0,
  AdultPadInformation: 0,
  AdultPadPakInfo: 0,
  PediatricPadInformation: 0,
  PediatricPakPadInformation: 0,
}

// GatewayInformation
const EditAed = () => {
  const all_condition_true = 0;
  const routes = useParams();
  const navigate = useNavigate();
  const AccId = routes?.id;
  const AEDId = routes?.aedId;
  const siteId = routes?.siteId;

  // const [AccountId,setAccoutnId] = useState()
  const [AccLidatData, setaccLidatData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [showAddRow, setShowAddRow] = useState(sectioNameObj);
  const [RmsDropdown, SetRmsDropdown] = useState([]);
  const [BatteryList, setBatteryList] = useState([]);
  const [FormMsg, setFormMsg] = useState({ type: true, msg: "" });
  const [aedDetails, setAedDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const Permissins = useSelector((state) => state?.AED_manager?.permissions);
  const dispatch = useDispatch();
  const PermissionRedux = useSelector(
    (state) => state?.AED_manager?.permissions
  );
  const AEDLoader = useSelector((state) => state?.AED_manager?.AEDLoader);

  const location = useLocation();
  const aedDetailsData = location?.state?.detailsData;

  const forResetData = location?.state?.detailsData;

  // AEDLoader

  const [Permissins1, setPermission] = useState({
    auto: 0,
    brand_id: 0,
    created_by_id: null,
    created_date: "2023-05-04T19:51:56.000Z",
    discontinued: 0,
    display: 0,
    has_9v: 0,
    has_10pk: 0,
    has_battery: 1,
    has_builtin_rms: 0,
    has_chargepak: 0,
    has_gateway: 0,
    has_installby: 0,
    has_man: 0,
    has_pad: 0,
    has_padpak: 0,
    has_ped_key: 0,
    has_ped_pad: 0,

    id: 0,
    image_file_name: "",
    model_name: "",
    model_partnumber: null,
    modified_by_id: null,
    modified_date: null,
    semi: 0,
  });

  const spare_battery_info = {
    battery_type_id: "",
    battery_expiration: "",
    battery_lot: "",
    battery_uid: "",
    v9_install: "",
    install_before_date: "",
    date_installed: "",
    manufactured_date: "",
    battery_serial: "",
    add_row: 1,
    is_spare: 1,
  };

  const SpareChargePackInfo = {
    spare_charge_pak_part: "",
    spare_charge_pak_uiid: "",
    spare_battery_expiration: "",
    spare_battery_lot: "",
    spare_pad_1_part: "",
    spare_pad_1_expiration: "",
    spare_pad_1_lot: "",
    spare_pad_2_part: "",
    spare_pad_2_expiration: "",
    spare_pad_2_lot: "",
    add_row: 1,
    is_spare: 1,
    section_name: "charge_pack",
  };

  const SpareAdultPadInfo = {
    // pad_part: "",
    pad_type_id: "",
    pad_part_number: "",
    pad_expiration: "",
    pad_lot: "",
    pad_udi: "",
    add_row: 1,
    is_spare: 1,
    section_name: "spare_adult_pad_info",
  };

  const sparePadricPadInfo = {
    // pad_part: "",
    pad_type_id: "",
    pad_part_number: "",
    pad_expiration: "",
    pad_lot: "",
    pad_UDI: "",
    add_row: 1,
    is_spare: 1,
  };

  const sparePadricPakPad = {
    // pad_part: "",
    pad_type_id: "",
    pad_part_number: "",
    pad_expiration: "",
    pad_lot: "",
    pad_UDI: "",
    add_row: 1,
    is_spare: 1,
  };

  const SpareAdultPadPakInfo = {
    // pad_part: "",
    pad_type_id: "",
    pad_part_number: "",
    pad_expiration: "",
    pad_lot: "",
    pad_UDI: "",
    add_row: 1,
    is_spare: 1,
  };
  const AdultPadInfoVAl = {
    // pad_part: "",
    pad_type_id: "",
    pad_expiration: "",
    pad_lot: "",
    pad_UDI: "",
  };
  const ChargePakInfoVal = {
    pad_type_id: "",
    charge_pak_part: "",
    charge_pak_uiid: "",
    battery_expiration: "",
    battery_lot: "",
    pad_1_part: "",
    pad_1_expiration: "",
    pad_1_lot: "",
    pad_2_part: "",
    pad_2_expiration: "",
    pad_2_lot: "",
  };

  const PediatricPadInformationVal = {
    // pad_part: "",
    pad_type_id: "",
    pad_expiration: "",
    pad_lot: "",
    pad_UDI: "",
  };

  const PediatricPakPadInformationVal = {
    // pad_part: "",
    pad_type_id: "",
    pad_expiration: "",
    pad_lot: "",
    pad_UDI: "",
  };
  const GateWayInfoVal = {
    installed: "",
    connected: "",
    gateway_serial: "",
    gateway_Mmac_address: "",
    battery_install_date: "",
  };
  const [formData, setFormData] = useState({
    // General Information
    account_id: AccId,
    account: AccId,
    account_name: "",
    site: "",
    brand: "",
    brandName: "",
    model_name: "",
    part_no: "",
    sub_model_id: "",
    serial: "",
    asset: "",
    other1_lable: "",
    other2_lable: "",
    other1: "",
    other2: "",
    file_name: "",
    placement: "",
    purchase_type: "",
    purchase_date: "",
    rms_brand: "",
    part_number: "",

    no_spares_toggle: 1,
    no_pediatric_toggle: 1,
    RMS_toggle: 0,
    out_of_service_toggle: 0,
    no_spare_padric_pad_info_toggle: 0,

    // Out of Service Information
    replaced_serial: "",
    replaced_serial_name: "",
    date_sent_to_manufacturer: "",
    loaner_rental_serial: "",
    loaner_rental_serial_name: "",
    reason: "",
    not_replacing: false,
    loaner_serial_id: 0,

    // StorageInformation
    storage_type: "",
    storage_part_name: "",
    alarmed: "",
    alarm_status: "",
    v9_Installed_Date: "",

    has_battery: [
      {
        battery_type_id: "",
        battery_expiration: "",
        battery_lot: "",
        battery_uid: "",
        v9_install: "",
        install_before_date: "",
        date_installed: "",
        manufactured_date: "",
        battery_serial: "",
      },
    ],
    has_battery_spare: [],

    has_9v: [
      {
        battery_type_id: "",
        battery_expiration: "",
        battery_lot: "",
        battery_uid: "",
        v9_install: "",
        install_before_date: "",
        date_installed: "",
        manufactured_date: "",
        battery_serial: "",
      },
    ],

    has_9v_spare: [],

    has_installby: [
      {
        battery_type_id: "",
        battery_expiration: "",
        battery_lot: "",
        battery_uid: "",
        v9_install: "",
        install_before_date: "",
        date_installed: "",
        manufactured_date: "",
        battery_serial: "",
      },
    ],
    has_installby_spare: [],

    has_man: [
      {
        battery_type_id: "",
        battery_expiration: "",
        battery_lot: "",
        battery_uid: "",
        v9_install: "",
        install_before_date: "",
        date_installed: "",
        manufactured_date: "",
        battery_serial: "",
      },
    ],

    has_man_spare: [],

    has_10pk: [
      {
        battery_type_id: "",
        battery_expiration: "",
        battery_lot: "",
        battery_uid: "",
        v9_install: "",
        install_before_date: "",
        date_installed: "",
        manufactured_date: "",
        battery_serial: "",
      },
    ],
    has_10pk_spare: [],

    battery_info: [
      {
        battery_type_id: "",
        battery_expiration: "",
        battery_lot: "",
        battery_uid: "",
        v9_install: "",
        install_before_date: "",
        date_installed: "",
        manufactured_date: "",
        battery_serial: "",
      },
    ],

    spare_battery_info: [],

    battery_info1: [
      {
        battery_type_id: "",
        battery_expiration: "",
        battery_lot: "",
        battery_uid: "",
        v9_install: "",
        install_before_date: "",
        date_installed: "",
        manufactured_date: "",
        battery_serial: "",
      },
    ],

    ChargePakInformation: [ChargePakInfoVal],
    SpareChargePakInformation: [],
    AdultPadInformation: [AdultPadInfoVAl],
    SpareAdultPadInfo: [],
    AdultPadPakInfo: [AdultPadInfoVAl],
    SpareAdultPadPakInfo: [],
    PediatricPadInformation: [PediatricPadInformationVal],
    sparePadricPadInfo: [],
    PediatricPakPadInformation: [PediatricPakPadInformationVal],
    sparePadricPakPad: [],

    GatewayInformation: [GateWayInfoVal],
    BuiltInRMSInformation: [
      {
        connected: false,
        mac_address: "",
        alarmed: "",
      },
    ],
    pediatric_key: false,

    adult_pad_info: [AdultPadInfoVAl],
    spare_adult_pad_info: [],
    adult_pad_pak_info: [AdultPadInfoVAl],
    spare_adult_pad_pak_info: [],
    pediatric_pad_info: [PediatricPadInformationVal],
    spare_padric_pad_info: [],
    pediatric_pak_pad_info: [PediatricPadInformationVal],
    spare_padric_pak_pad: [],
    store_expiry_date: "",
    dni_array_list: [],
    deletedBatteried: [],
    unKnownArr: [],
    useSbidInventory: [],
    useSpidInventory: [],
  });

  // function findMatchingSections(aedBatteryInfo, sectionName, is_spare) {
  //   const matchingBatteries = [];
  //   // Loop through the array to find objects with matching section_name and is_spare
  //   for (let i = 0; i < aedBatteryInfo.length; i++) {
  //     if (
  //       aedBatteryInfo[i].section_name == sectionName &&
  //       Number(aedBatteryInfo[i].is_spare) === is_spare
  //     ) {
  //       matchingBatteries.push(aedBatteryInfo[i]);
  //     }
  //   }
  //   // If no matching objects are found, return exists: 0 and an empty array
  //   if (matchingBatteries.length > 0) {
  //     return { exists: 1, data: matchingBatteries };
  //   } else {
  //     return { exists: 0, data: [] };
  //   }
  // }

  const handleEditDispatch = () => {

    const editPageUrl = "/account/aed/edit/" + AEDId + "/" + siteId; // Replace this with your edit page's URL

    dispatch(removeBreadcrumb(editPageUrl));

  }


  function findMatchingSections(aedBatteryInfo, sectionName, is_spare) {
    const matchingBatteries = [];
    // Loop through the array to find objects with matching section_name and is_spare
    for (let i = 0; i < aedBatteryInfo.length; i++) {
      if (aedBatteryInfo[i].section_name == sectionName && Number(aedBatteryInfo[i].is_spare) === is_spare) {
        if(sectionName == "has_9v"){
          const data = {...aedBatteryInfo[i]};
          data.v9_install = aedBatteryInfo[i].install_9v_date;
          matchingBatteries.push(data);

        } else if(sectionName == "has_man" || sectionName == "has_installby" || sectionName == "has_10pk") {
          // aedBatteryInfo[i].date_installed = aedBatteryInfo[i].install_date;
          // matchingBatteries.push(aedBatteryInfo[i]);
          const newbattery = { ...aedBatteryInfo[i] };
          newbattery.date_installed = aedBatteryInfo[i].install_date;
          matchingBatteries.push(newbattery);

        } else if(sectionName == "charge_pack") {
          const newData = {...aedBatteryInfo[i]};
          newData.charge_pak_part = aedBatteryInfo[i].battery_type_id;
          // aedBatteryInfo[i].charge_pak_part = aedBatteryInfo[i].battery_type_id;
          matchingBatteries.push(newData);
          
        } else {
          const data = {...aedBatteryInfo[i]};
          matchingBatteries.push(data);
        }
      }
    }
    // If no matching objects are found, return exists: 0 and an empty array
    if (matchingBatteries.length > 0) {
      return { exists: 1, data: matchingBatteries };
    } else {
      return { exists: 0, data: [] };
    }
  }

  function mergePadInfo(hasChargePakBatterySpare, hasPadChargePakInfo) {
    return hasChargePakBatterySpare.map((spareItem) => {
      // hasPadChargePakInfo se match hone wala object dhundho jaha pid == charge_pak_pad_1_id
      const matchedPadInfo1 = hasPadChargePakInfo.find(
        (padInfo) => padInfo.pid == spareItem.charge_pak_pad_1_id
      );

      if (matchedPadInfo1) {
        // Required keys ko add karo matchedPadInfo se spareItem me
        spareItem.charge_pak_pad_1_id = matchedPadInfo1?.pad_type_id;
        spareItem.pad_1_type_id = matchedPadInfo1?.pad_type_id;
        spareItem.pad_1_part_number = matchedPadInfo1.pad_part_number;
        // spareItem.pad_type_1_id = matchedPadInfo1.pad_type_id;
        spareItem.pad_1_lot = matchedPadInfo1.pad_lot;
        spareItem.pad_1_expiration = matchedPadInfo1.pad_expiration;
      }

      const matchedPadInfo2 = hasPadChargePakInfo.find(
        (padInfo) => padInfo.pid == spareItem.charge_pak_pad_2_id
      );

      if (matchedPadInfo2) {
        // Required keys ko add karo matchedPadInfo se spareItem me
        spareItem.charge_pak_pad_2_id = matchedPadInfo2?.pad_type_id;
        spareItem.pad_2_type_id = matchedPadInfo2?.pad_type_id;
        spareItem.pad_2_part_number = matchedPadInfo2.pad_part_number;
        // spareItem.pad_type_2_id = matchedPadInfo2.pad_type_id;
        spareItem.pad_2_lot = matchedPadInfo2.pad_lot;
        spareItem.pad_2_expiration = matchedPadInfo2.pad_expiration;
      }

      return spareItem;
    });
  }

  useEffect(() => {
    if (aedDetailsData) {
      // for Main Battery
      const hasManBattery = findMatchingSections(
        aedDetailsData?.aedBatteryInfo,
        "has_man",
        0
      );
      const hasBattery = findMatchingSections(
        aedDetailsData?.aedBatteryInfo,
        "has_battery",
        0
      );
      const has9VBattery = findMatchingSections(
        aedDetailsData?.aedBatteryInfo,
        "has_9v",
        0
      );
      const has10PKBattery = findMatchingSections(
        aedDetailsData?.aedBatteryInfo,
        "has_10pk",
        0
      );
      const hasInstallByBattery = findMatchingSections(
        aedDetailsData?.aedBatteryInfo,
        "has_installby",
        0
      );
      const hasChargePakBattery = findMatchingSections(
        aedDetailsData?.aedBatteryInfo,
        "charge_pack",
        0
      );

      // for Spare Battery
      const hasManBatterySpare = findMatchingSections(
        aedDetailsData?.aedBatteryInfo,
        "has_man",
        1
      );
      const hasBatterySpare = findMatchingSections(
        aedDetailsData?.aedBatteryInfo,
        "has_battery",
        1
      );
      const has9VBatterySpare = findMatchingSections(
        aedDetailsData?.aedBatteryInfo,
        "has_9v",
        1
      );
      const has10PKBatterySpare = findMatchingSections(
        aedDetailsData?.aedBatteryInfo,
        "has_10pk",
        1
      );
      const hasInstallByBatterySpare = findMatchingSections(
        aedDetailsData?.aedBatteryInfo,
        "has_installby",
        1
      );
      const hasChargePakBatterySpare = findMatchingSections(
        aedDetailsData?.aedBatteryInfo,
        "charge_pack",
        1
      );

      // for Main Pad
      const adultPad = findMatchingSections(
        aedDetailsData?.aedPadInfo,
        "adult_pad_info",
        0
      );
      const adultPadPak = findMatchingSections(
        aedDetailsData?.aedPadInfo,
        "adult_pad_pak_info",
        0
      );
      const pediatricPad = findMatchingSections(
        aedDetailsData?.aedPadInfo,
        "pediatric_pad_info",
        0
      );
      const pediatricPadPak = findMatchingSections(
        aedDetailsData?.aedPadInfo,
        "pediatric_pak_pad_info",
        0
      );

      // for Spare Pad
      const adultPadSpare = findMatchingSections(
        aedDetailsData?.aedPadInfo,
        "spare_adult_pad_info",
        1
      );
      const adultPadPakSpare = findMatchingSections(
        aedDetailsData?.aedPadInfo,
        "spare_adult_pad_pak_info",
        1
      );
      const pediatricPadSpare = findMatchingSections(
        aedDetailsData?.aedPadInfo,
        "spare_padric_pad_info",
        1
      );
      const pediatricPadPakSpare = findMatchingSections(
        aedDetailsData?.aedPadInfo,
        "spare_padric_pak_pad",
        1
      );

      const chargepak = mergePadInfo(
        hasChargePakBattery?.data,
        aedDetailsData?.aedPadInfo
      );
      const spare = mergePadInfo(
        hasChargePakBatterySpare?.data,
        aedDetailsData?.aedPadInfo
      );

      function parseJSONSafely(input) {
        if (typeof input === "string") {
          try {
            return JSON.parse(input);
          } catch (error) {
            console.error("Invalid JSON:", error);
            return null; // Ya default value return kar sakte hain, jaise empty object ya array
          }
        } else if (typeof input === "object" && input !== null) {
          return input; // Agar already object hai, to usi ko return karo
        } else {
          console.warn("Unexpected data type:", typeof input);
          return null; // Ya default value
        }
      }

      const outOfServiceInfo = parseJSONSafely(
        aedDetailsData?.aedGeneralInfo?.out_of_service_info
      );

      setFormData((prev) => ({
        ...prev,
        account_id: aedDetailsData?.aedGeneralInfo?.account_id,
        account_name: aedDetailsData?.aedGeneralInfo?.account_name,
        site_id: aedDetailsData?.aedGeneralInfo?.site_id,
        site: aedDetailsData?.aedGeneralInfo?.site_name,
        brand: aedDetailsData?.aedGeneralInfo?.aed_brand_id,
        brandName: aedDetailsData?.aedGeneralInfo?.brandName,
        aed_model_id: aedDetailsData?.aedGeneralInfo?.aed_model_id,
        model_name: aedDetailsData?.aedGeneralInfo?.aed_model_id,
        modelName: aedDetailsData?.aedGeneralInfo?.modelName,
        sub_model_id: aedDetailsData?.aedGeneralInfo?.sub_model_id,
        sub_model_name: aedDetailsData?.aedGeneralInfo?.sub_model_name,
        part_number: aedDetailsData?.aedGeneralInfo?.part_number,
        serial_number: aedDetailsData?.aedGeneralInfo?.serial_number,
        file_name: aedDetailsData?.aedGeneralInfo?.aed_image,
        asset: aedDetailsData?.aedGeneralInfo?.asset,
        placement: aedDetailsData?.aedGeneralInfo?.placement,
        purchase_type: aedDetailsData?.aedGeneralInfo?.purchase_type,
        purchase_date: aedDetailsData?.aedGeneralInfo?.purchase_date,
        warenty_date: aedDetailsData?.aedGeneralInfo?.warenty_date,
        no_spares_toggle: aedDetailsData?.aedGeneralInfo?.no_spares_toggle,

        other1: aedDetailsData?.aedGeneralInfo?.other?.length > 0 ? aedDetailsData?.aedGeneralInfo?.other[0]?.val : "",
        other1_lable: aedDetailsData?.aedGeneralInfo?.other?.length > 0 ? aedDetailsData?.aedGeneralInfo?.other[0]?.label : "",
        other2: aedDetailsData?.aedGeneralInfo?.other?.length > 1 ? aedDetailsData?.aedGeneralInfo?.other[1]?.val : "",
        other2_lable: aedDetailsData?.aedGeneralInfo?.other?.length > 1 ? aedDetailsData?.aedGeneralInfo?.other[1]?.label : "",

        no_pediatric_toggle:
          aedDetailsData?.aedGeneralInfo?.no_pediatric_toggle,
        RMS_toggle: aedDetailsData?.aedGeneralInfo?.RMS_toggle,
        out_of_service_toggle:
          aedDetailsData?.aedGeneralInfo?.out_of_service_toggle,
        storage_type: aedDetailsData?.aedStorageInfo[0]?.storage_type,
        storage_part_name: aedDetailsData?.aedStorageInfo[0]?.storage_part_name,
        alarmed: aedDetailsData?.aedStorageInfo[0]?.alarmed,
        alarm_status: aedDetailsData?.aedStorageInfo[0]?.alarm_status,
        v9_Installed_Date: aedDetailsData?.aedStorageInfo[0]?.v9_Installed_Date,
        expiry_date: aedDetailsData?.aedStorageInfo[0]?.expiry_date,
        rms_brand: aedDetailsData?.aedGeneralInfo?.rms_info[0]?.rms_brand,
        rms_name: aedDetailsData?.aedGeneralInfo?.rms_info[0]?.rms_name,
        out_of_service_info: parseJSONSafely(
          aedDetailsData?.aedGeneralInfo?.out_of_service_info
        ),
        // && JSON.parse(aedDetailsData?.aedGeneralInfo?.out_of_service_info),
        // out_of_service_info: {
        // not_replacing: JSON.parse(aedDetailsData?.aedGeneralInfo?.out_of_service_info)[0]?.not_replacing,
        // replaced_serial: JSON.parse(aedDetailsData?.aedGeneralInfo?.out_of_service_info)[0]?.replaced_serial,
        // replaced_serial_name: JSON.parse(aedDetailsData?.aedGeneralInfo?.out_of_service_info)[0]?.replaced_serial_name,
        // loaner_toggle: JSON.parse(aedDetailsData?.aedGeneralInfo?.out_of_service_info)[0]?.loaner_toggle,
        // loaner_rental_serial: JSON.parse(aedDetailsData?.aedGeneralInfo?.out_of_service_info)[0]?.loaner_rental_serial,
        // loaner_rental_serial_name: JSON.parse(aedDetailsData?.aedGeneralInfo?.out_of_service_info)[0]?.loaner_rental_serial_name,
        // date_sent_to_manufacturer: JSON.parse(aedDetailsData?.aedGeneralInfo?.out_of_service_info)[0]?.date_sent_to_manufacturer,
        // reason: JSON.parse(aedDetailsData?.aedGeneralInfo?.out_of_service_info)[0]?.reason,
        // }

        not_replacing: outOfServiceInfo[0]?.not_replacing,
        replaced_serial: outOfServiceInfo[0]?.replaced_serial,
        replaced_serial_name: outOfServiceInfo[0]?.replaced_serial_name,
        loaner_toggle: outOfServiceInfo[0]?.loaner_toggle,
        loaner_rental_serial: outOfServiceInfo[0]?.loaner_rental_serial,
        loaner_rental_serial_name:
          outOfServiceInfo[0]?.loaner_rental_serial_name,
        date_sent_to_manufacturer:
          outOfServiceInfo[0]?.date_sent_to_manufacturer,
        reason: outOfServiceInfo[0]?.reason,

        // for Main Battery
        has_man: hasManBattery?.data,
        has_installby: hasInstallByBattery?.data,
        has_battery: hasBattery?.data,
        has_10pk: has10PKBattery?.data,
        has_9v: has9VBattery?.data,
        ChargePakInformation: hasChargePakBattery?.data?.length > 0 ? hasChargePakBattery?.data : [ChargePakInfoVal],

        // for Spare Battery
        has_man_spare: hasManBatterySpare?.data,
        has_installby_spare: hasInstallByBatterySpare?.data,
        has_battery_spare: hasBatterySpare?.data,
        has_10pk_spare: has10PKBatterySpare?.data,
        has_9v_spare: has9VBatterySpare?.data,
        SpareChargePakInformation: hasChargePakBatterySpare?.data,

        // for Main Pad
        AdultPadInformation: (Number(PermissionRedux?.has_pad) === 1 && adultPad?.data?.length < 0) ? [AdultPadInfoVAl] : adultPad?.data,
        AdultPadPakInfo: (Number(PermissionRedux?.has_padpak) === 1 && adultPad?.data?.length < 0) ? [AdultPadInfoVAl] : adultPadPak?.data,
        PediatricPadInformation: (Number(PermissionRedux?.has_ped_pad) === 1 && adultPad?.data?.length < 0) ? [PediatricPadInformationVal] : pediatricPad?.data,
        PediatricPakPadInformation: (Number(PermissionRedux?.has_padpak) === 1 && adultPad?.data?.length < 0) ? [PediatricPakPadInformationVal] : pediatricPadPak?.data,

        // for Spare Pad
        SpareAdultPadInfo: adultPadSpare?.data,
        SpareAdultPadPakInfo: adultPadPakSpare?.data,
        sparePadricPadInfo: pediatricPadSpare?.data,
        sparePadricPakPad: pediatricPadPakSpare?.data,

        pediatric_key:
          Number(aedDetailsData?.aedGeneralInfo?.pediatric_key) === 1 ? 1 : 0,
        GatewayInformation: aedDetailsData?.aedGeneralInfo?.gateway_info,
        BuiltInRMSInformation: aedDetailsData?.aedGeneralInfo?.builtin_RMS_info,
      }));
    }
  }, []);

  // console.log({ aedDetailsData });
  // console.log({ formData });

  useEffect(() => {
  }, [formData]);

  // const [accountData,setAccountData] = useState(null);
  const fetchAccount = async () => {
    if (formData?.account) {
      let fd = { ...formData };
      let res = await FetchAccountDetails(formData.account);
      // aed_check_length
      let len = res?.aed_check_length || "15 Days";
      let len_days = len.split(" ")?.[0];
      let crrDate = moment();
      let check_date = moment(crrDate).add(len_days, "day");

      fd.other1_lable = res?.extra_field1;
      fd.other2_lable = res?.extra_field2;
      fd.check_date = check_date;
      setFormData(fd);
    }
  };

  const [currentAccountId, setCurrentAccountId] = useState(null);

  const onLoad = async () => {
    const res = await CallGETAPI("account/get-aed-by-id/" + AEDId);
    const allPadsData = res?.data?.allPads;
    const batteryInfoData = res?.data?.batteryInfo;

    if (res.status) {
      setCurrentAccountId(res?.data?.data?.account_id);
    }

    dispatch(updateAEDLoading(1));
    let AccoutnList = await GetAccountList();
    let brandList = await GetAedBrands();
    let RmsBList = await GetRMSBrand();
    let AEDFromData = await FetchAEDDetails(AEDId);
    // "charge_pack" , "adult_pad_info", "spare_adult_pad_info", "adult_pad_pak_info", "spare_adult_pad_pak_info", "pediatric_pad_info", "pediatric_pak_pad_info"
    const CP = [],
      API = [],
      SAPI = [],
      APPI = [],
      SAPPI = [],
      PPI = [],
      SPPI = [],
      PPPI = [],
      SPPPI = [];

    // AEDFromData.AdultPadInformation = allPadsData

    AEDFromData.adult_pad_info = JSON.parse(AEDFromData?.adult_pad_info);
    AEDFromData.AdultPadInformation = AEDFromData?.adult_pad_info;
    AEDFromData.adult_pad_pak_info = JSON.parse(
      AEDFromData?.adult_pad_pak_info
    );
    AEDFromData.AdultPadPakInfo = AEDFromData?.adult_pad_pak_info;
    // AEDFromData.AdultPadPakInfo = allPadsData

    AEDFromData.battery_info = JSON.parse(AEDFromData?.battery_info);
    // const HB = [], H9V=[], h10PK=[], HIB=[], HM=[];
    const BI = {
      charge_pack: [],
      has_battery: [],
      has_9v: [],
      has_10pk: [],
      has_installby: [],
      has_man: [],
    };
    const SPBI = {
      spare_charge_pack: [],
      has_battery_spare: [],
      has_9v_spare: [],
      has_10pk_spare: [],
      has_installby_spare: [],
      has_man_spare: [],
    };
    for (let bii = 0; bii < batteryInfoData?.length; bii++) {
      const b2 = batteryInfoData[bii] || false;
      if (!b2) {
        break;
      }
      if (b2.is_spare) {
        const key = b2.section_name + "_spare";
        if (SPBI[key]) {
          SPBI[key].push(b2);
        }
      } else {
        const key = b2.section_name;
        if (BI[key]) {
          BI[key].push(b2);
        }
      }
    }

    AEDFromData.has_battery = BI.has_battery; // AEDFromData?.battery_info?.[0]?.has_battery ? AEDFromData?.battery_info?.[0]?.has_battery : []
    AEDFromData.has_9v = BI.has_9v; //AEDFromData?.battery_info?.[0]?.has_9v ? AEDFromData?.battery_info?.[0]?.has_9v : []
    AEDFromData.has_10pk = BI.has_10pk; //AEDFromData?.battery_info?.[0]?.has_10pk ? AEDFromData?.battery_info?.[0]?.has_10pk : []
    AEDFromData.has_installby = BI.has_installby; //AEDFromData?.battery_info?.[0]?.has_installby ? AEDFromData?.battery_info?.[0]?.has_installby : []
    AEDFromData.has_man = BI.has_man; //AEDFromData?.battery_info?.[0].has_man ? AEDFromData?.battery_info?.[0]?.has_man : []

    AEDFromData.builtin_RMS_info = AEDFromData?.builtin_RMS_info
      ? JSON.parse(AEDFromData?.builtin_RMS_info)
      : [];
    AEDFromData.BuiltInRMSInformation = AEDFromData?.builtin_RMS_info;

    const ar1 = [];
    const SPar1 = [];
    const PartnumberPads = [];
    for (let ci = 0; ci < batteryInfoData?.length; ci++) {
      const ell = batteryInfoData[ci];
      if (ell.section_name === "charge_pack") {
        const ChargePakInfoObj = {
          battery_expiration: ell?.battery_expiration,
          battery_lot: ell?.battery_lot,
          charge_pak_part: ell?.battery_type_id,
          charge_pak_uiid: ell?.charge_pak_uid,
          charge_pak_uid: ell?.charge_pak_uid,
          section_name: ell?.section_name,
          is_spare: ell?.is_spare,
          bid: ell?.bid,
          pad_1_expiration: "",
          pad_1_lot: "",
          pad_1_part: "",
          pad_2_expiration: "",
          pad_2_lot: "",
          pad_2_part: "",
          pad_type_id: "",
        };

        for (let i3 = 0; i3 < allPadsData.length; i3++) {
          const apd = allPadsData[i3];
          if (apd?.section_name === "charge_pack") {
            if (apd?.pid === Number(ell?.charge_pak_pad_1_id)) {
              ChargePakInfoObj.pad_1_expiration = apd?.pad_expiration;
              ChargePakInfoObj.pad_1_lot = apd?.pad_lot;
              ChargePakInfoObj.pad_1_part = apd?.pad_type_id;
            } else if (apd?.pid === Number(ell?.charge_pak_pad_2_id)) {
              ChargePakInfoObj.pad_2_expiration = apd?.pad_expiration;
              ChargePakInfoObj.pad_2_lot = apd?.pad_lot;
              ChargePakInfoObj.pad_2_part = apd?.pad_type_id;
            }
          }
        }
        if (ell.is_spare) {
          SPar1.push(ChargePakInfoObj);
        } else {
          ar1.push(ChargePakInfoObj);
        }
      }
    }
    AEDFromData.charge_pak_info = ar1.length > 0 ? ar1 : [ChargePakInfoVal];
    AEDFromData.ChargePakInformation = AEDFromData?.charge_pak_info || [
      ChargePakInfoVal,
    ];
    AEDFromData.spare_charge_pak_info = SPar1;
    AEDFromData.SpareChargePakInformation = AEDFromData?.spare_charge_pak_info;

    AEDFromData.has_chargepak = AEDFromData?.charge_pak_info[0]?.has_chargepak;
    AEDFromData.gateway_info = JSON.parse(AEDFromData?.gateway_info);
    AEDFromData.GatewayInformation = AEDFromData?.gateway_info;
    AEDFromData.other = JSON.parse(AEDFromData?.other);
    AEDFromData.out_of_service_info = JSON.parse(
      AEDFromData?.out_of_service_info
    );
    AEDFromData.pediatric_pad_info = JSON.parse(
      AEDFromData?.pediatric_pad_info
    );
    AEDFromData.PediatricPadInformation = AEDFromData?.pediatric_pad_info;
    // AEDFromData.PediatricPadInformation = allPadsData

    AEDFromData.pediatric_pak_pad_info = JSON.parse(
      AEDFromData?.pediatric_pak_pad_info
    );
    AEDFromData.PediatricPakPadInformation =
      AEDFromData?.pediatric_pak_pad_info;
    // AEDFromData.PediatricPakPadInformation = allPadsData

    AEDFromData.rms_info = JSON.parse(AEDFromData?.rms_info);
    AEDFromData.spare_adult_pad_info = JSON.parse(
      AEDFromData?.spare_adult_pad_info
    );
    AEDFromData.SpareAdultPadInfo = AEDFromData?.spare_adult_pad_info;
    // AEDFromData.SpareAdultPadInfo = allPadsData

    AEDFromData.spare_adult_pad_pak_info = JSON.parse(
      AEDFromData?.spare_adult_pad_pak_info
    );
    // AEDFromData.SpareAdultPadPakInfo = allPadsData

    AEDFromData.spare_battery_info = JSON.parse(
      AEDFromData?.spare_battery_info
    );
    AEDFromData.has_battery_spare = SPBI?.has_battery_spare || []; //AEDFromData?.spare_battery_info?.[0]?.has_battery_spare ? AEDFromData?.spare_battery_info?.[0]?.has_battery_spare : []
    AEDFromData.has_9v_spare = SPBI?.has_9v_spare || []; //AEDFromData?.spare_battery_info?.[0]?.has_9v_spare ? AEDFromData?.spare_battery_info?.[0]?.has_9v_spare : []
    AEDFromData.has_10pk_spare = SPBI?.has_10pk_spare || []; //AEDFromData?.spare_battery_info?.[0]?.has_10pk_spare ? AEDFromData?.spare_battery_info?.[0]?.has_10pk_spare : []
    AEDFromData.has_installby_spare = SPBI?.has_installby_spare || []; //AEDFromData?.spare_battery_info?.[0]?.has_installby_spare ? AEDFromData?.spare_battery_info?.[0]?.has_installby_spare : []
    AEDFromData.has_man_spare = SPBI?.has_man_spare || []; //AEDFromData?.spare_battery_info?.[0]?.has_man_spare ? AEDFromData?.spare_battery_info?.[0]?.has_man_spare : []

    // AEDFromData.spare_charge_pak_info = JSON.parse(
    //   AEDFromData?.spare_charge_pak_info
    // );
    // AEDFromData.SpareChargePakInformation = AEDFromData?.spare_charge_pak_info;

    AEDFromData.spare_padric_pad_info = JSON.parse(
      AEDFromData?.spare_padric_pad_info
    );
    AEDFromData.sparePadricPadInfo = AEDFromData?.spare_padric_pad_info;
    // AEDFromData.sparePadricPadInfo = allPadsData

    AEDFromData.spare_padric_pak_pad = JSON.parse(
      AEDFromData?.spare_padric_pak_pad
    );
    // AEDFromData.sparePadricPakPad = allPadsData

    AEDFromData.storage_info = JSON.parse(AEDFromData?.storage_info);
    AEDFromData.storage_type = AEDFromData?.storage_info?.length > 0 ? 1 : 0;
    AEDFromData.alarmed = AEDFromData?.storage_info[0]?.alarmed ? 1 : 0;
    AEDFromData.alarm_status = AEDFromData?.storage_info[0]?.alarm_status
      ? 1
      : 0;
    AEDFromData.v9_Installed_Date =
      AEDFromData?.storage_info[0]?.v9_Installed_Date;
    AEDFromData.store_expiry_date = AEDFromData?.storage_info[0]?.expiry_date;
    AEDFromData.expiry_date = AEDFromData?.storage_info[0]?.expiry_date;
    // store_expiry_date
    AEDFromData.storage_type = AEDFromData?.storage_info[0]?.storage_type;
    AEDFromData.file_name = AEDFromData?.aed_image;
    AEDFromData.model_name = AEDFromData?.aed_model_id;
    AEDFromData.brand = AEDFromData?.aed_brand_id;
    AEDFromData.serial = AEDFromData?.serial_number;
    AEDFromData.sub_model_id = AEDFromData?.sub_model_id;
    AEDFromData.rms_brand = AEDFromData?.rms_info?.[0]?.rms_brand || "";

    // all pads
    // PediatricPadInformation, PediatricPakPadInformation,SpareAdultPadInfo,SpareAdultPadPakInfo,SpareChargePakInformation, sparePadricPadInfo,sparePadricPakPad

    const AP = [],
      SAP = [],
      PP = [],
      SPP = [],
      APP = [],
      SAPP = [],
      PPP = [],
      SPPP = [],
      CP2 = [],
      SCP = [];
    for (let api = 0; api < allPadsData.length; api++) {
      const APD = allPadsData[api] || false;
      if (!APD) {
        break;
      }
      if (
        APD.section_name === "charge_pack" ||
        APD.section_name === "spare_charge_pack"
      ) {
        APD.pad_part = APD.pad_type_id;

        if (APD.is_spare) {
          SCP.push(APD);
        } else {
          CP2.push(APD);
        }
      }

      if (
        APD.section_name === "adult_pad_info" ||
        APD.section_name === "spare_adult_pad_info"
      ) {
        APD.pad_part = APD.pad_type_id;

        if (APD.is_spare) {
          SAP.push(APD);
        } else {
          AP.push(APD);
        }
      }

      if (
        APD.section_name === "adult_pad_pak_info" ||
        APD.section_name === "spare_adult_pad_pak_info"
      ) {
        APD.pad_part = APD.pad_type_id;

        if (APD.is_spare) {
          SAPP.push(APD);
        } else {
          APP.push(APD);
        }
      }

      if (
        APD.section_name === "pediatric_pad_info" ||
        APD.section_name === "spare_pediatric_pad_info" ||
        APD.section_name === "spare_padric_pad_info"
      ) {
        APD.pad_part = APD.pad_type_id;
        if (APD.is_spare) {
          SPP.push(APD);
        } else {
          PP.push(APD);
        }
      }

      if (
        APD.section_name === "pediatric_pak_pad_info" ||
        APD.section_name === "spare_pediatric_pak_pad_info" ||
        APD.section_name === "spare_padric_pak_pad"
      ) {
        APD.pad_part = APD.pad_type_id;

        if (APD.is_spare) {
          SPPP.push(APD);
        } else {
          PPP.push(APD);
        }
      }
    }
    if (PP.length === 0) {
      PP.push(PediatricPadInformationVal);
    }
    // PediatricPakPadInformation pediatric_pad_info spare_padric_pad_info
    AEDFromData.AdultPadInformation = AP; //aedDetails1?.adult_pad_info ||[];
    AEDFromData.adult_pad_info = AP; //aedDetails1?.adult_pad_info ||[];
    AEDFromData.SpareAdultPadInfo = SAP; //aedDetails1?.spare_adult_pad_info ||[];
    AEDFromData.spare_adult_pad_info = SAP; //aedDetails1?.spare_adult_pad_info ||[];
    AEDFromData.PediatricPadInformation = PP; //aedDetails1?.pediatric_pad_info ||[];
    AEDFromData.pediatric_pad_info = PP; //aedDetails1?.pediatric_pad_info ||[];
    AEDFromData.sparePadricPadInfo = SPP; //aedDetails1?.spare_padric_pad_info ||[];
    AEDFromData.spare_padric_pad_info = SPP; //aedDetails1?.spare_padric_pad_info ||[];
    AEDFromData.AdultPadPakInfo = APP; //aedDetails1?.adult_pad_pak_info ||[];
    AEDFromData.adult_pad_pak_info = APP; //aedDetails1?.adult_pad_pak_info ||[];
    AEDFromData.SpareAdultPadPakInfo = SAPP; //aedDetails1?.spare_adult_pad_pak_info ||[];
    AEDFromData.spare_adult_pad_pak_info = SAPP; //aedDetails1?.spare_adult_pad_pak_info ||[];
    AEDFromData.PediatricPakPadInformation = PPP; //aedDetails1?.pediatric_pak_pad_info ||[];
    AEDFromData.pediatric_pak_pad_info = PPP; //aedDetails1?.pediatric_pak_pad_info ||[];
    AEDFromData.sparePadricPakPad = SPPP; //aedDetails1?.spare_padric_pak_pad || [];
    AEDFromData.spare_padric_pak_pad = SPPP; //aedDetails1?.spare_padric_pak_pad || [];
    AEDFromData.no_spares_toggle = AEDFromData?.no_spares_toggle;
    // no_spares_toggle
    setAedDetails(AEDFromData);
    setFormData((old) => ({
      ...old,
      ...AEDFromData,
      ...AEDFromData?.out_of_service_info[0],
    }));
    // let PermissinsData = {
    //     auto: 0,
    //     brand_id: AEDFromData.brand,
    //     created_by_id: AEDFromData.created_by_id,
    //     created_date: AEDFromData.created_date,
    //     discontinued: 0,
    //     display: 0,
    //     has_9v: AEDFromData.battery_info[0].has_9v?.length > 0 ? 1 : 0,
    //     has_10pk: AEDFromData.battery_info[0].has_10pk?.length > 0 ? 1 : 0,
    //     has_battery: AEDFromData?.has_battery?.length > 0 ? 1 : 0,
    //     has_builtin_rms: AEDFromData.builtin_RMS_info?.length > 0 ? 1 : 0,
    //     has_chargepak: AEDFromData.battery_info[0].charge_pak_info?.length > 0 ? 1 : 0,
    //     has_gateway:  AEDFromData?.gateway_info?.length > 0 ? 1 : 0,
    //     has_installby: AEDFromData.battery_info[0].has_installby?.length > 0 ? 1 : 0,
    //     has_man: AEDFromData.battery_info[0].has_man?.length > 0 ? 1 : 0,
    //     has_pad: AEDFromData.adult_pad_info?.length > 0 ? 1 : 0,
    //     has_padpak: AEDFromData.pediatric_pak_pad_info?.length > 0 ? 1 : 0,
    //     has_ped_key: AEDFromData.pediatric_key,
    //     has_ped_pad: AEDFromData.pediatric_pad_info?.length > 0 ? 1 : 0,
    //     id: AEDFromData.aed_id,
    //     image_file_name: AEDFromData.file_name,
    //     model_name: AEDFromData.model_name,
    //     model_partnumber: null,
    //     modified_by_id: AEDFromData.modified_by_id,
    //     modified_date: AEDFromData.modified_date,
    //     semi: 0,
    // }

    // setPermission((old) => ({...old, ...PermissinsData}));
    // dispatch(updatePermission(PermissinsData));

    let accListData = AccoutnList?.data?.data?.account || [];
    let bradlistData = brandList?.data || [];

    SetRmsDropdown(RmsBList);
    setaccLidatData(accListData);
    setBrandData(bradlistData);

    setShowLoading(false);
    dispatch(updateAEDLoading(0));
  };

  const getBatteryType = async () => {
    let betteryData = await BatteryTypebyModel(formData?.model_name);
    setBatteryList(betteryData);
  };

  const handleInput = (e) => {
    let val = e.target.value;
    let name = e.target.name;
    setFormData((old) => ({ ...old, [name]: val }));
  };

  const handleCheckBox = (e) => {
    if (e.target.type == "checkbox") {
      setFormData((old) => ({ ...old, [e.target.name]: e.target.checked }));
      noToogleHandler(e);
    } else {
      setFormData((old) => ({ ...old, [e.target.name]: e.target.value }));
    }
  };

  // no toogle handle
  const noToogleHandler = (e) => {
    const name = e.target.name;
    const value = e.target.checked;
    let FD = { ...formData };

    setFormData((old) => ({ ...old, [name]: value }));
    if (name == "no_battery_spare_toggle") {
      let info = fillArrayValuesUnknown(FD?.has_battery_spare, value);
      setFormData((old) => ({ ...old, ["has_battery_spare"]: info }));
    } else if (name == "no_9v_spare_toggle") {
      let info = fillArrayValuesUnknown(FD?.has_9v_spare, value);
      setFormData((old) => ({ ...old, ["has_9v_spare"]: info }));
    } else if (name == "no_installby_spare_toggle") {
      let info = fillArrayValuesUnknown(FD?.has_installby_spare, value);
      setFormData((old) => ({ ...old, ["has_installby_spare"]: info }));
    } else if (name == "no_has_10pk_spare_toggle") {
      let info = fillArrayValuesUnknown(FD?.has_10pk_spare, value);
      setFormData((old) => ({ ...old, ["has_10pk_spare"]: info }));
    } else if (name == "no_has_man_spare_toggle") {
      let info = fillArrayValuesUnknown(FD?.has_man_spare, value);
      setFormData((old) => ({ ...old, ["has_man_spare"]: info }));
    } else if (name == "no_spare_charge_pak_info_toggle") {
      let info = fillArrayValuesUnknown(FD?.SpareChargePakInformation, value);
      setFormData((old) => ({ ...old, ["SpareChargePakInformation"]: info }));
    } else if (name == "no_spare_adult_pak_info_toggle") {
      let info = fillArrayValuesUnknown(FD?.SpareAdultPadInfo, value);
      setFormData((old) => ({ ...old, ["SpareAdultPadInfo"]: info }));
    } else if (name == "no_spare_adult_pad_pak_info_toggle") {
      let info = fillArrayValuesUnknown(FD?.SpareAdultPadPakInfo, value);
      setFormData((old) => ({ ...old, ["SpareAdultPadPakInfo"]: info }));
    } else if (name == "no_spare_padric_pad_info_toggle") {
      let info = fillArrayValuesUnknown(FD?.sparePadricPadInfo, value);
      setFormData((old) => ({ ...old, ["sparePadricPadInfo"]: info }));
    } else if (name == "no_spare_padric_pad_pak_info_toggle") {
      let info = fillArrayValuesUnknown(FD?.sparePadricPakPad, value);
      setFormData((old) => ({ ...old, ["sparePadricPakPad"]: info }));
    } else if (name == "no_has_battery_toggle") {
      let info = fillArrayValuesUnknown(FD?.has_battery, value);
      setFormData((old) => ({ ...old, ["has_battery"]: info }));
    } else if (name == "no_has_9v_toggle") {
      let info = fillArrayValuesUnknown(FD?.has_9v, value);
      setFormData((old) => ({ ...old, ["has_9v"]: info }));
    } else if (name == "no_has_installby_toggle") {
      let info = fillArrayValuesUnknown(FD?.has_installby, value);
      setFormData((old) => ({ ...old, ["has_installby"]: info }));
    } else if (name == "no_has_man_toggle") {
      let info = fillArrayValuesUnknown(FD?.has_man, value);
      setFormData((old) => ({ ...old, ["has_man"]: info }));
    } else if (name == "no_has_10pk_toggle") {
      let info = fillArrayValuesUnknown(FD?.has_10pk, value);
      setFormData((old) => ({ ...old, ["has_10pk"]: info }));
    } else if (name == "no_has_chargepak_toggle") {
      let info = fillArrayValuesUnknown(FD?.ChargePakInformation, value);
      setFormData((old) => ({ ...old, ["ChargePakInformation"]: info }));
    } else if (name == "no_has_pad_toggle") {
      let info = fillArrayValuesUnknown(FD?.AdultPadInformation, value);
      setFormData((old) => ({ ...old, ["AdultPadInformation"]: info }));
    } else if (name == "no_has_padpak_toggle") {
      let info = fillArrayValuesUnknown(FD?.AdultPadPakInfo, value);
      setFormData((old) => ({ ...old, ["AdultPadPakInfo"]: info }));
    } else if (name == "no_has_pedpad_toggle") {
      let info = fillArrayValuesUnknown(FD?.PediatricPadInformation, value);
      setFormData((old) => ({ ...old, ["PediatricPadInformation"]: info }));
    } else if (name == "no_has_pedpak_toggle") {
      let info = fillArrayValuesUnknown(FD?.PediatricPakPadInformation, value);
      setFormData((old) => ({ ...old, ["PediatricPakPadInformation"]: info }));
    }
  };

  // pass value to unknown
  const fillArrayValuesUnknown = (array, toogleValue) => {
    const newArray = array.map((obj) => {
      const updatedObj = {};
      for (const key in obj) {
        if (key.includes("part")) {
          updatedObj[key] = toogleValue ? "unknown" : "";
        } else {
          updatedObj[key] = toogleValue ? "unknown" : "";
        }
      }
      return updatedObj;
    });

    return newArray;
  };

  const addMorebattery_info = (type) => {
    if (type === "add") {
      let arr = { ...formData };
      let crr = arr.battery_info[arr?.battery_info.length - 1];
      // if (keyName === "SpareAdultPadInfo") {
      //   crr = SpareAdultPadInfo;
      // }
      arr.battery_info.push(crr);
      setFormData(arr);
    } else {
      let arr = { ...formData };
      // if (arr.battery_info.length > 1)
      // {
      //     arr.battery_info.pop();
      //     setFormData(arr);
      // }
      arr.battery_info.pop();
      setFormData(arr);
    }
  };

  const addMorespare_battery_info = () => {
    let arr = { ...formData };
    let crr = arr.spare_battery_info[arr?.spare_battery_info.length - 1];
    arr.spare_battery_info.push(crr);
    setFormData(arr);
  };

  const Removespare_battery_info = (index) => {
    let arr = { ...formData };
    // if (arr?.spare_battery_info.length > 1)
    // {
    //     arr.spare_battery_info.pop();
    //     setFormData(arr);
    // }
    arr.spare_battery_info.pop();
    setFormData(arr);
  };

  const addRemoveChargePakInfo = (type, keyName) => {
    if (type === "add") {
      let arr = { ...formData };
      let crr = arr[keyName].length
        ? arr[keyName][arr[keyName].length - 1]
        : [];
      if (
        keyName === "spare_battery_info" ||
        keyName === "has_battery_spare" ||
        keyName === "has_9v_spare" ||
        keyName === "has_installby_spare" ||
        keyName === "has_man_spare" ||
        keyName === "has_10pk_spare"
      ) {
        crr = spare_battery_info;
      }

      if (keyName === "SpareChargePakInformation") {
        crr = SpareChargePackInfo;
      }

      if (keyName === "SpareAdultPadInfo") {
        crr = SpareAdultPadInfo;
      }

      if (keyName === "SpareAdultPadPakInfo") {
        crr = SpareAdultPadPakInfo;
      }
      if (keyName === "sparePadricPadInfo") {
        crr = sparePadricPadInfo;
      }

      if (keyName === "sparePadricPakPad") {
        crr = sparePadricPakPad;
      }

      if (keyName && arr[keyName] && crr) {
        arr[keyName].push(crr);
        setFormData(arr);
      }
    } else {
      let arr = { ...formData };

      const arrey = arr[keyName][arr[keyName].length - 1];
      if (arrey?.sbid) {
        const updatedArray = removeFirstOccurrenceSbid(
          arr,
          "useSbidInventory",
          arrey?.sbid
        );
      }

      if (arrey?.spid) {
        const updatedArray = removeFirstOccurrenceSbid(
          arr,
          "useSpidInventory",
          arrey?.spid
        );
      }

      // if (arr[ keyName ].length > 1)
      // {
      //     arr[ keyName ].pop();
      //     setFormData(arr);
      // }
      arr[keyName].pop();
      setFormData(arr);
    }
  };

  function removeFirstOccurrenceSbid(arr, keyName, sbid) {
    const inventoryArray = arr[keyName];
    const index = inventoryArray.indexOf(sbid);

    if (index !== -1) {
      inventoryArray.splice(index, 1);
    }

    return inventoryArray;
  }

  // useEffect(() => {
  //   onLoad();
  // }, []);

  // useEffect(() =>
  // {
  //     fetchAccount();
  // }, [ formData?.account ]);

  const [padList, setPadList] = useState([]);

  const fetchAEDPads = async () => {
    let result = await PadTypeByModal(formData?.model_name);
    setPadList(result);
  };

  useEffect(() => {
    if (formData?.model_name) {
      getBatteryType();
    }
  }, [formData.model_name]);

  // Function to filter keys based on provided template object
  function filterArrayByKeys(array, templateArray) {
    return array.map(item => {
      // Create a new object which only contains the keys present in the template array
      let filteredItem = {};
      Object.keys(templateArray[0]).forEach(key => {
        if (item.hasOwnProperty(key)) {
          filteredItem[key] = item[key];
        }
      });
      return filteredItem;
    });
  }

  // The main function
  function matchAndFilter(array, templateName) {
    // Define all the templates for matching
    const templates = {
      has_man: [
        {
          "battery_type_id": "1",
          "battery_expiration": "09/10/2028",
          "battery_lot": "1",
          "date_installed": "2024-09-10",
          "manufactured_date": "2024-09-09",
          "battery_udi": "2"
        }
      ],

      has_man_spare: [
        {
          "battery_type_id": "1",
          "battery_expiration": "09/10/2028",
          "battery_lot": "1",
          "date_installed": "2024-09-10",
          "manufactured_date": "2024-09-09",
          "battery_udi": "2"
        }
      ],

      has_battery: [
        {
          "battery_type_id": "17",
          "battery_expiration": "2024-09-09",
          "battery_lot": "1",
          "battery_udi": "1"
        }
      ],
      has_battery_spare: [
        {
          "battery_type_id": "17",
          "battery_expiration": "2024-09-09",
          "battery_lot": "1",
          "battery_udi": "1",
          "no_battery_spare_toggle": false
        }
      ],
      has_9v: [
        {
          "battery_type_id": "4",
          "battery_expiration": "2024-09-09",
          "battery_lot": "1",
          "v9_install": "2024-09-09",
          "battery_udi": "1"
        }
      ],
      has_9v_spare: [
        {
          "battery_type_id": "4",
          "battery_expiration": "2024-09-10",
          "battery_lot": "1",
          "battery_udi": "2",
          "v9_install": "2024-09-10",
          "no_battery_spare_toggle": false
        }
      ],
      has_installby: [
        {
          "battery_type_id": "7",
          "battery_expiration": "09/10/2028",
          "battery_lot": "1",
          "install_before_date": "2024-09-09",
          "date_installed": "2024-09-10",
          "battery_serial": "3",
          "battery_udi": "1"
        }
      ],
      has_installby_spare: [
        {
          "battery_type_id": "7",
          "battery_lot": "csdbchj",
          "battery_udi": "bjhvbhj",
          "install_before_date": "2024-09-09",
          "battery_serial": "1",
          "no_battery_spare_toggle": false
        }
      ],
      has_10pk: [
        {
          "battery_type_id": "15",
          "battery_expiration": "09/09/2028",
          "date_installed": "2024-09-09"
        }
      ],
      has_10pk_spare: [
        {
          "battery_type_id": "15",
          "install_before_date": "2024-09-10",
          "no_battery_spare_toggle": false
        }
      ]
    };

    // Get the corresponding template array based on the passed template name
    const templateArray = templates[templateName];

    if (!templateArray) {
      console.error("Invalid template name provided");
      return [];
    }

    // Filter the array based on the template keys
    return filterArrayByKeys(array, templateArray);
  }

  console.log({ PermissionRedux });

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    let batteries_expirations = [];
    const mainFormData = { ...formData };

    formData?.has_battery.map((item) => {
      if (item?.battery_expiration && item?.battery_expiration != "") {
        batteries_expirations.push(item?.battery_expiration);
      }
    });
    formData?.has_9v.map((item) => {
      if (item?.battery_expiration && item?.battery_expiration != "") {
        batteries_expirations.push(item?.battery_expiration);
      }
    });

    formData?.has_installby.map((item) => {
      if (item?.battery_expiration && item?.battery_expiration != "") {
        batteries_expirations.push(item?.battery_expiration);
      }
    });

    formData?.has_man.map((item) => {
      if (item?.battery_expiration && item?.battery_expiration != "") {
        batteries_expirations.push(item?.battery_expiration);
      }
    });

    formData?.has_10pk.map((item) => {
      if (item?.battery_expiration && item?.battery_expiration != "") {
        batteries_expirations.push(item?.battery_expiration);
      }
    });
    let adult_pad_exp_date = [];
    formData?.AdultPadInformation.map((item) => {
      if (item?.adult_pad_expiration) {
        adult_pad_exp_date.push(item?.adult_pad_expiration);
      }
    });

    if (PermissionRedux?.has_battery) {
      const if_has_btery = editCheckIfAnyKeyHasValue(formData?.has_battery);
      if (!if_has_btery) {
        let n = "no_has_battery_toggle";
        let v = 1;
        let FD = { ...formData };
        FD[n] = v;

        let n2 = "has_battery";
        let v2 = editUpdateJsonArrayWithUnknown(formData?.has_battery);
        FD[n2] = v2;
        mainFormData[n] = v;
        mainFormData[n2] = v2;
        setFormData(FD);
      }
    }

    // spare has battry
    if (formData?.no_spares_toggle && PermissionRedux?.has_battery) {
      const if_has_btery = checkIfAnyKeyHasValue(formData?.has_battery_spare);
      if (!if_has_btery) {
        let n = "no_battery_spare_toggle";
        let v = 1;
        let FD = { ...formData };
        FD[n] = v;
        let n2 = "has_battery_spare";
        let v2 = updateJsonArrayWithUnknown(formData?.has_battery_spare);
        FD[n2] = v2;

        mainFormData[n] = v;
        mainFormData[n2] = v2;
        setFormData(FD);
      }
    }

    if (PermissionRedux?.has_9v) {
      const if_has_btery = editCheckIfAnyKeyHasValue(formData?.has_9v);
      if (!if_has_btery) {
        let n = "no_has_9v_toggle";
        let v = 1;
        let FD = { ...formData };
        FD[n] = v;
        let n2 = "has_9v";
        let v2 = editUpdateJsonArrayWithUnknown(formData?.has_9v);
        FD[n2] = v2;

        mainFormData[n] = v;
        mainFormData[n2] = v2;
        setFormData(FD);
      }
    }

    // spare has Has 9v
    if (formData?.no_spares_toggle && PermissionRedux?.has_9v) {
      const if_has_btery = checkIfAnyKeyHasValue(formData?.has_9v_spare);
      if (!if_has_btery) {
        let n = "no_9v_spare_toggle";
        let v = 1;
        let FD = { ...formData };
        FD[n] = v;
        let n2 = "has_9v_spare";
        let v2 = updateJsonArrayWithUnknown(formData?.has_9v_spare);
        FD[n2] = v2;

        mainFormData[n] = v;
        mainFormData[n2] = v2;
        setFormData(FD);
      }
    }

    if (PermissionRedux?.has_installby) {
      const if_has_btery = editCheckIfAnyKeyHasValue(formData?.has_installby);
      if (!if_has_btery) {
        let n = "no_has_installby_toggle";
        let v = 1;
        let FD = { ...formData };
        FD[n] = v;
        let n2 = "has_installby";
        let v2 = editUpdateJsonArrayWithUnknown(formData?.has_installby);
        FD[n2] = v2;

        mainFormData[n] = v;
        mainFormData[n2] = v2;
        setFormData(FD);
      }
    }

    // spare has installby
    if (formData?.no_spares_toggle && PermissionRedux?.has_installby) {
      const if_has_btery = checkIfAnyKeyHasValue(formData?.has_installby_spare);
      if (!if_has_btery) {
        let n = "no_installby_spare_toggle";
        let v = 1;
        let FD = { ...formData };
        FD[n] = v;
        let n2 = "has_installby_spare";
        let v2 = updateJsonArrayWithUnknown(formData?.has_installby_spare);
        FD[n2] = v2;

        mainFormData[n] = v;
        mainFormData[n2] = v2;
        setFormData(FD);
      }
    }

    if (PermissionRedux?.has_man) {
      const if_has_btery = editCheckIfAnyKeyHasValue(formData?.has_man);
      if (!if_has_btery) {
        let n = "no_has_man_toggle";
        let v = 1;
        let FD = { ...formData };
        FD[n] = v;
        let n2 = "has_man";
        let v2 = editUpdateJsonArrayWithUnknown(formData?.has_man);
        FD[n2] = v2;

        mainFormData[n] = v;
        mainFormData[n2] = v2;
        setFormData(FD);
      }
    }

    // spare has has_man
    if (formData?.no_spares_toggle && PermissionRedux?.has_man) {
      const if_has_btery = checkIfAnyKeyHasValue(formData?.has_man_spare);
      if (!if_has_btery) {
        let n = "no_has_man_spare_toggle";
        let v = 1;
        let FD = { ...formData };
        FD[n] = v;
        let n2 = "has_man_spare";
        let v2 = updateJsonArrayWithUnknown(formData?.has_man_spare);
        FD[n2] = v2;

        mainFormData[n] = v;
        mainFormData[n2] = v2;
        setFormData(FD);
      }
    }

    if (PermissionRedux?.has_10pk) {
      const if_has_btery = editCheckIfAnyKeyHasValue(formData?.has_10pk);
      if (!if_has_btery) {
        let n = "no_has_10pk_toggle";
        let v = 1;
        let FD = { ...formData };
        FD[n] = v;
        let n2 = "has_10pk";
        let v2 = editUpdateJsonArrayWithUnknown(formData?.has_10pk);
        FD[n2] = v2;

        mainFormData[n] = v;
        mainFormData[n2] = v2;
        setFormData(FD);
      }
    }

    // spare has 10pk
    if (formData?.no_spares_toggle && PermissionRedux?.has_10pk) {
      const if_has_btery = checkIfAnyKeyHasValue(formData?.has_10pk_spare);
      if (!if_has_btery) {
        let n = "no_has_10pk_spare_toggle";
        let v = 1;
        let FD = { ...formData };
        FD[n] = v;
        let n2 = "has_10pk_spare";
        let v2 = updateJsonArrayWithUnknown(formData?.has_10pk_spare);
        FD[n2] = v2;

        mainFormData[n] = v;
        mainFormData[n2] = v2;
        setFormData(FD);
      }
    }

    if (formData?.no_spares_toggle && PermissionRedux?.has_pad) {
      const if_has_btery = checkIfAnyKeyHasValue(formData?.SpareAdultPadInfo);
      if (!if_has_btery) {
        let n = "no_spare_adult_pak_info_toggle";
        let v = 1;
        let FD = { ...formData };
        FD[n] = v;
        let n2 = "SpareAdultPadInfo";
        let v2 = updateJsonArrayWithUnknown(formData?.SpareAdultPadInfo);
        FD[n2] = v2;

        mainFormData[n] = v;
        mainFormData[n2] = v2;
        setFormData(FD);
      }
    }
    // if(PermissionRedux?.has_pad){
    //     const if_has_btery  = checkIfAnyKeyHasValue(formData?.AdultPadInformation);
    //     if(!if_has_btery){
    //         let n  = 'no_has_pad_toggle';
    //         let v  = 1;
    //         let FD = {...formData }
    //         FD[n]  = v;
    //         let n2 = 'AdultPadInformation';
    //         let v2 = updateJsonArrayWithUnknown(formData?.AdultPadInformation);
    //         FD[n2] = v2;

    //         mainFormData[n] = v;
    //         mainFormData[n2] = v2;
    //         setFormData(FD);
    //     }
    // }

    if (PermissionRedux?.has_padpak) {
      const if_has_btery = checkIfAnyKeyHasValue(formData?.AdultPadPakInfo);
      if (!if_has_btery) {
        let n = "no_has_padpak_toggle";
        let v = 1;
        let FD = { ...formData };
        FD[n] = v;
        let n2 = "AdultPadPakInfo";
        let v2 = updateJsonArrayWithUnknown(formData?.AdultPadPakInfo);
        FD[n2] = v2;
        mainFormData[n] = v;
        mainFormData[n2] = v2;
        setFormData(FD);
      }
    }

    if (formData?.no_spares_toggle && PermissionRedux?.has_padpak) {
      const if_has_btery = checkIfAnyKeyHasValue(
        formData?.SpareAdultPadPakInfo
      );
      if (!if_has_btery) {
        let n = "no_spare_adult_pad_pak_info_toggle";
        let v = 1;
        let FD = { ...formData };
        FD[n] = v;
        let n2 = "SpareAdultPadPakInfo";
        let v2 = updateJsonArrayWithUnknown(formData?.SpareAdultPadPakInfo);
        FD[n2] = v2;
        mainFormData[n] = v;
        mainFormData[n2] = v2;
        setFormData(FD);
      }
    }

    // if (formData.no_pediatric_toggle && PermissionRedux?.has_ped_pad) {
    //   const if_has_btery = checkIfAnyKeyHasValue(
    //     formData?.PediatricPadInformation
    //   );
    //   if (!if_has_btery) {
    //     let n = "no_has_pedpad_toggle";
    //     let v = 1;
    //     let FD = { ...formData };
    //     FD[n] = v;
    //     let n2 = "PediatricPadInformation";
    //     let v2 = updateJsonArrayWithUnknown(formData?.PediatricPadInformation);
    //     FD[n2] = v2;
    //     mainFormData[n] = v;
    //     mainFormData[n2] = v2;
    //     setFormData(FD);
    //   }
    // }

    // if (
    //   formData?.no_spares_toggle &&
    //   formData.no_pediatric_toggle &&
    //   PermissionRedux?.has_ped_pad
    // ) {
    //   const if_has_btery = checkIfAnyKeyHasValue(formData?.sparePadricPadInfo);
    //   if (!if_has_btery) {
    //     let n = "no_spare_padric_pad_info_toggle";
    //     let v = 1;
    //     let FD = { ...formData };
    //     FD[n] = v;
    //     let n2 = "sparePadricPadInfo";
    //     let v2 = updateJsonArrayWithUnknown(formData?.sparePadricPadInfo);
    //     FD[n2] = v2;
    //     mainFormData[n] = v;
    //     mainFormData[n2] = v2;
    //     setFormData(FD);
    //   }
    // }

    // if(formData.no_pediatric_toggle && PermissionRedux?.has_padpak){
    //     const if_has_btery  = checkIfAnyKeyHasValue(formData?.sparePadricPadInfo);
    //     if(!if_has_btery){
    //         let n  = 'no_spare_padric_pad_info_toggle';
    //         let v  = 1;
    //         let FD = {...formData }
    //         FD[n]  = v;
    //         let n2 = 'sparePadricPadInfo';
    //         let v2 = updateJsonArrayWithUnknown(formData?.sparePadricPadInfo);
    //         FD[n2] = v2;
    //         mainFormData[n] = v;
    //         mainFormData[n2] = v2;
    //         setFormData(FD);
    //     }
    // }

    // has_padpak sparePadricPadInfo
    // if (
    //   formData?.no_spares_toggle &&
    //   formData.no_pediatric_toggle &&
    //   PermissionRedux?.has_padpak
    // ) {
    //   const if_has_btery = checkIfAnyKeyHasValue(formData?.sparePadricPakPad);
    //   if (!if_has_btery) {
    //     let n = "no_spare_padric_pad_info_toggle";
    //     let v = 1;
    //     let FD = { ...formData };
    //     FD[n] = v;
    //     let n2 = "sparePadricPakPad";
    //     let v2 = updateJsonArrayWithUnknown(formData?.sparePadricPakPad);
    //     FD[n2] = v2;
    //     mainFormData[n] = v;
    //     mainFormData[n2] = v2;
    //     setFormData(FD);
    //   }
    // }
    if (PermissionRedux?.has_chargepak) {
      const if_has_btery = editCheckIfAnyKeyHasValue(
        formData?.ChargePakInformation
      );
      if (!if_has_btery) {
        let n = "no_has_chargepak_toggle";
        let v = 1;
        let FD = { ...formData };
        FD[n] = v;
        let n2 = "ChargePakInformation";
        let v2 = editUpdateJsonArrayWithUnknown(formData?.ChargePakInformation);
        FD[n2] = v2;
        mainFormData[n] = v;
        mainFormData[n2] = v2;
        setFormData(FD);
      }
    }
    if (PermissionRedux?.has_chargepak) {
      const if_has_btery = editCheckIfAnyKeyHasValue(formData?.charge_pak_info);
      if (!if_has_btery) {
        let n = "no_has_chargepak_toggle";
        let v = 1;
        let FD = { ...formData };
        FD[n] = v;
        let n2 = "charge_pak_info";
        let v2 = editUpdateJsonArrayWithUnknown(
          formData?.charge_pak_info ? formData?.charge_pak_info : []
        );
        FD[n2] = v2;
        mainFormData[n] = v;
        mainFormData[n2] = v2;
        setFormData(FD);
      }
    }
    if (formData?.no_spares_toggle && PermissionRedux?.has_chargepak) {
      const if_has_btery = checkIfAnyKeyHasValue(
        formData?.SpareChargePakInformation
      );
      if (!if_has_btery) {
        let n = "no_spare_charge_pak_info_toggle";
        let v = 1;
        let FD = { ...formData };
        FD[n] = v;
        let n2 = "SpareChargePakInformation";
        let v2 = updateJsonArrayWithUnknown(
          formData?.SpareChargePakInformation
        );
        FD[n2] = v2;
        mainFormData[n] = v;
        mainFormData[n2] = v2;
        setFormData(FD);
      }
    }

    //  Edit
    if (PermissionRedux?.has_pad) {
      const if_has_btery = editCheckIfAnyKeyHasValue(formData?.AdultPadInformation);
      if (!if_has_btery) {
        let n = "no_has_pad_toggle";
        let v = 1;
        let FD = { ...formData };
        FD[n] = v;
        let n2 = "AdultPadInformation";
        let v2 = editUpdateJsonArrayWithUnknown(formData?.AdultPadInformation);
        FD[n2] = v2;
        mainFormData[n] = v;
        mainFormData[n2] = v2;
        setFormData(FD);
      }
    } else {
      let FD = { ...formData };
      let n2 = "AdultPadInformation";
      let v2 = [AdultPadInfoVAl];
      FD[n2] = v2;
      mainFormData[n2] = v2;
      setFormData(FD);
    }

    /*
        adult_pad_info: [AdultPadInfoVAl],
        spare_adult_pad_info:[],
        adult_pad_pak_info:[  AdultPadInfoVAl ],
        spare_adult_pad_pak_info:[],
        pediatric_pad_info:[ PediatricPadInformationVal ],
        spare_padric_pad_info:[],
        pediatric_pak_pad_info:[  PediatricPadInformationVal ],
        spare_padric_pak_pad:[], */
    if (formData?.no_spares_toggle && PermissionRedux?.has_pad) {
      const if_has_btery = checkIfAnyKeyHasValue(
        formData?.spare_adult_pad_info
      );
      if (!if_has_btery) {
        let n = "no_has_pad_toggle";
        let v = 1;
        let FD = { ...formData };
        FD[n] = v;
        let n2 = "spare_adult_pad_info";
        let v2 = updateJsonArrayWithUnknown(formData?.spare_adult_pad_info);
        FD[n2] = v2;
        mainFormData[n] = v;
        mainFormData[n2] = v2;
        setFormData(FD);
      }
    } else {
      let FD = { ...formData };
      let n2 = "spare_adult_pad_info";
      let v2 = [];
      FD[n2] = v2;
      mainFormData[n2] = v2;
      setFormData(FD);
    }

    if (PermissionRedux?.has_padpak) {
      const if_has_btery = editCheckIfAnyKeyHasValue(formData?.adult_pad_pak_info);
      if (!if_has_btery) {
        let n = "no_has_padpak_toggle";
        let v = 1;
        let FD = { ...formData };
        FD[n] = v;
        let n2 = "adult_pad_pak_info";
        let v2 = editUpdateJsonArrayWithUnknown(formData?.adult_pad_pak_info);
        FD[n2] = v2;
        mainFormData[n] = v;
        mainFormData[n2] = v2;
        setFormData(FD);
      }
    } else {
      let FD = { ...formData };
      let n2 = "adult_pad_pak_info";
      let v2 = [AdultPadInfoVAl];
      FD[n2] = v2;
      mainFormData[n2] = v2;
      setFormData(FD);
    }

    if (formData?.no_spares_toggle && PermissionRedux?.has_padpak) {
      const if_has_btery = checkIfAnyKeyHasValue(
        formData?.spare_adult_pad_pak_info
      );
      if (!if_has_btery) {
        let n = "no_spare_adult_pad_pak_info_toggle";
        let v = 1;
        let FD = { ...formData };
        FD[n] = v;
        let n2 = "spare_adult_pad_pak_info";
        let v2 = updateJsonArrayWithUnknown(formData?.spare_adult_pad_pak_info);
        FD[n2] = v2;
        mainFormData[n] = v;
        mainFormData[n2] = v2;
        setFormData(FD);
      }
    } else {
      let FD = { ...formData };
      let n2 = "spare_adult_pad_pak_info";
      let v2 = [];
      FD[n2] = v2;
      mainFormData[n2] = v2;
      setFormData(FD);
    }

    // if (formData.no_pediatric_toggle && PermissionRedux?.has_ped_pad) {
    //   const if_has_btery = checkIfAnyKeyHasValue(formData?.pediatric_pad_info);
    //   if (!if_has_btery) {
    //     // let n = "no_has_pedpad_toggle";
    //     // let v = 1;
    //     // let FD = { ...formData };
    //     // FD[n] = v;
    //     // let n2 = "pediatric_pad_info";
    //     // let v2 = updateJsonArrayWithUnknown(formData?.pediatric_pad_info);
    //     // FD[n2] = v2;
    //     // mainFormData[n] = v;
    //     // mainFormData[n2] = v2;
    //     // setFormData(FD);
    //   }
    // } else {
    //   let FD = { ...formData };
    //   let n2 = "pediatric_pad_info";
    //   let v2 = [PediatricPadInformationVal];
    //   FD[n2] = v2;
    //   mainFormData[n2] = v2;
    //   setFormData(FD);
    // }

    // if (
    //   formData?.no_spares_toggle &&
    //   formData.no_pediatric_toggle &&
    //   PermissionRedux?.has_ped_pad
    // ) {
    //   const if_has_btery = checkIfAnyKeyHasValue(
    //     formData?.spare_padric_pad_info
    //   );
    //   if (!if_has_btery) {
    //     // let n = "no_spare_padric_pad_info_toggle";
    //     // let v = 1;
    //     // let FD = { ...formData };
    //     // FD[n] = v;
    //     // let n2 = "spare_padric_pad_info";
    //     // let v2 = updateJsonArrayWithUnknown(formData?.spare_padric_pad_info);
    //     // FD[n2] = v2;
    //     // mainFormData[n] = v;
    //     // mainFormData[n2] = v2;
    //     // setFormData(FD);
    //   }
    // } else {
    //   let FD = { ...formData };
    //   let n2 = "spare_padric_pad_info";
    //   let v2 = [];
    //   FD[n2] = v2;
    //   mainFormData[n2] = v2;
    //   setFormData(FD);
    // }

    // if (
    //   formData?.no_spares_toggle &&
    //   formData.no_pediatric_toggle &&
    //   PermissionRedux?.has_padpak
    // ) {
    //   const if_has_btery = checkIfAnyKeyHasValue(
    //     formData?.spare_padric_pak_pad
    //   );
    //   if (!if_has_btery) {
    //     // let n = "no_spare_padric_pad_info_toggle";
    //     // let v = 1;
    //     // let FD = { ...formData };
    //     // FD[n] = v;
    //     // let n2 = "spare_padric_pak_pad";
    //     // let v2 = updateJsonArrayWithUnknown(formData?.spare_padric_pak_pad);
    //     // FD[n2] = v2;
    //     // mainFormData[n] = v;
    //     // mainFormData[n2] = v2;
    //     // setFormData(FD);
    //   }
    // } else {
    //   let FD = { ...formData };
    //   let n2 = "spare_padric_pak_pad";
    //   let v2 = [];
    //   FD[n2] = v2;
    //   mainFormData[n2] = v2;
    //   setFormData(FD);
    // }

    // if(PermissionRedux?.has_chargepak){
    //     const if_has_btery  = checkIfAnyKeyHasValue(formData?.ChargePakInformation);
    //     if(!if_has_btery){
    //         let n  = 'no_has_chargepak_toggle';
    //         let v  = 1;
    //         let FD = {...formData }
    //         FD[n]  = v;
    //         let n2 = 'ChargePakInformation';
    //         let v2 = updateJsonArrayWithUnknown(formData?.ChargePakInformation);
    //         FD[n2] = v2;
    //         mainFormData[n] = v;
    //         mainFormData[n2] = v2;
    //         setFormData(FD);
    //     }
    // }
    // else{
    //     let n2 = 'spare_padric_pad_info';
    //     let v2 = [];
    //     FD[n2] = v2;
    //     mainFormData[n] = v;
    //     mainFormData[n2] = v2;
    //     setFormData(FD);
    // }
    // if(PermissionRedux?.has_chargepak){
    //     const if_has_btery  = checkIfAnyKeyHasValue(formData?.charge_pak_info);
    //     if(!if_has_btery){
    //         let n  = 'no_has_chargepak_toggle';
    //         let v  = 1;
    //         let FD = {...formData }
    //         FD[n]  = v;
    //         let n2 = 'charge_pak_info';
    //         let v2 = updateJsonArrayWithUnknown(formData?.charge_pak_info);
    //         FD[n2] = v2;
    //         mainFormData[n] = v;
    //         mainFormData[n2] = v2;
    //         setFormData(FD);
    //     }
    // }
    // else{
    //     let n2 = 'spare_padric_pad_info';
    //     let v2 = [];
    //     FD[n2] = v2;
    //     mainFormData[n] = v;
    //     mainFormData[n2] = v2;
    //     setFormData(FD);
    // }
    // if(PermissionRedux?.has_chargepak){
    //     const if_has_btery  = checkIfAnyKeyHasValue(formData?.SpareChargePakInformation);
    //     if(!if_has_btery){
    //         let n  = 'no_spare_charge_pak_info_toggle';
    //         let v  = 1;
    //         let FD = {...formData }
    //         FD[n]  = v;
    //         let n2 = 'SpareChargePakInformation';
    //         let v2 = updateJsonArrayWithUnknown(formData?.SpareChargePakInformation);
    //         FD[n2] = v2;
    //         mainFormData[n] = v;
    //         mainFormData[n2] = v2;
    //         setFormData(FD);
    //     }
    // }
    // else{
    //     let n2 = 'spare_padric_pad_info';
    //     let v2 = [];
    //     FD[n2] = v2;
    //     mainFormData[n] = v;
    //     mainFormData[n2] = v2;
    //     setFormData(FD);
    // }

    // let sendFormData = {
    //     account_id:     mainFormData?.account_id,
    //     site_id:        mainFormData?.site_id,
    //     aed_brand_id:   mainFormData?.brand,
    //     aed_model_id:   mainFormData?.model_name,
    //     part_no:        mainFormData?.part_no,
    //     sub_model_id:   mainFormData?.sub_model_id,
    //     serial_number:  mainFormData?.serial_number,
    //     serial:         mainFormData?.serial_number,
    //     rms_brand:      mainFormData?.rms_brand,

    //     asset:          mainFormData?.asset,
    //     other: [
    //         {
    //             label:  mainFormData?.other1_lable,
    //             val:    mainFormData?.other1,
    //         },
    //         {
    //             label:  mainFormData?.other2_lable,
    //             val:    mainFormData?.other2,
    //         }
    //     ],
    //     file_name:              mainFormData?.file_name,
    //     placement:              mainFormData?.placement || 'unknown',
    //     purchase_type:          mainFormData?.purchase_type,
    //     purchase_date:          mainFormData?.purchase_date,
    //     batteries_expirations:  batteries_expirations,
    //     adult_pad_exp_date:     adult_pad_exp_date,
    //     min_exp_date:           "",
    //     no_spares_toggle:       mainFormData?.no_spares_toggle || 0,
    //     no_pediatric_toggle:    mainFormData?.no_pediatric_toggle || 0,
    //     RMS_toggle:             mainFormData?.RMS_toggle || 0,
    //     out_of_service_toggle:  mainFormData?.out_of_service_toggle || 0,

    //     rms_info: [
    //         {
    //             rms_brand: mainFormData?.rms_brand,
    //         }
    //     ],

    //     out_of_service_info: [ {
    //         "date_sent_to_manufacturer":    mainFormData?.date_sent_to_manufacturer,
    //         "loaner_rental_serial":         mainFormData?.loaner_rental_serial,
    //         "reason":                       mainFormData?.reason,
    //         "replaced_serial":              mainFormData?.replaced_serial,
    //         "not_replacing":                mainFormData?.not_replacing,
    //         "storage_type":                 mainFormData?.storage_type,
    //         "alarmed":                      mainFormData?.alarmed,
    //         "v9_Installed_Date":            mainFormData?.v9_Installed_Date,
    //         "expiry_date":                  mainFormData?.v9_Installed_Date ? moment(mainFormData?.v9_Installed_Date).add(1, 'year') : "",
    //     } ],

    //     battery_info: [ {
    //         has_battery: mainFormData?.has_battery,
    //         has_9v: mainFormData?.has_9v,
    //         has_installby: mainFormData?.has_installby,
    //         has_man: mainFormData?.has_man,
    //         has_10pk: mainFormData?.has_10pk,
    //     } ],

    //     spare_battery_info: [ {
    //         has_battery_spare: mainFormData?.has_battery_spare,
    //         has_9v_spare: mainFormData?.has_9v_spare,
    //         has_installby_spare: mainFormData?.has_installby_spare,
    //         has_man_spare: mainFormData?.has_man_spare,
    //         has_10pk_spare: mainFormData?.has_10pk_spare,
    //     } ],

    //     // storage_info: [ {
    //     //     storage_type: mainFormData?.storage_typ,
    //     //     alarmed: mainFormData?.alarme,
    //     //     v9_Installed_Date: mainFormData?.v9_Installed_Dat,
    //     //     expiry_date: mainFormData?.store_expiry_date
    //     // } ],

    //     storage_info: [ {
    //         storage_type: mainFormData?.storage_type,
    //         alarmed: mainFormData?.alarmed,
    //         v9_Installed_Date: mainFormData?.alarmed ? mainFormData?.v9_Installed_Date : '',
    //         expiry_date: mainFormData?.alarmed ? mainFormData?.store_expiry_date : ''
    //         // ? moment(mainFormData?.v9_Installed_Date).add(1, 'year') : "",
    //     } ],
    //     charge_pak_info:            mainFormData?.charge_pak_info,
    //     spare_charge_pak_info:      mainFormData?.spare_charge_pak_info,
    //     adult_pad_info:             mainFormData?.AdultPadInformation,
    //     spare_adult_pad_info:       mainFormData?.spare_adult_pad_info,
    //     adult_pad_pak_info:         mainFormData?.adult_pad_pak_info,
    //     spare_adult_pad_pak_info:   mainFormData?.spare_adult_pad_pak_info,
    //     pediatric_pad_info:         mainFormData?.pediatric_pad_info,
    //     spare_padric_pad_info:      mainFormData?.spare_padric_pad_info,
    //     pediatric_pak_pad_info:     mainFormData?.pediatric_pak_pad_info,
    //     spare_padric_pak_pad:       mainFormData?.spare_padric_pak_pad,
    //     gateway_info:               mainFormData?.gateway_info,
    //     builtin_RMS_info:           mainFormData?.builtin_RMS_info,
    //     pediatric_key:              mainFormData?.pediatric_key,
    //     last_check:                 '', //formData?.check_date, Removed BY Gautam
    // };
    console.log({ mainFormData });
    let sendFormData = {
      account_id: mainFormData?.account_id,
      site_id: mainFormData?.site_id,
      aed_brand_id: mainFormData?.brand,
      aed_model_id: mainFormData?.model_name,
      part_number: mainFormData?.part_number,
      sub_model_id: mainFormData?.sub_model_id,
      serial_number: mainFormData?.serial_number,
      warranty_date: mainFormData?.warenty_date,
      asset: mainFormData?.asset,
      other: [
        {
          label: mainFormData?.other1_lable,
          val: mainFormData?.other1,
        },
        {
          label: mainFormData?.other2_lable,
          val: mainFormData?.other2,
        },
      ],
      file_name: mainFormData?.file_name,
      placement: mainFormData?.placement || "unknown",
      purchase_type: mainFormData?.purchase_type,
      purchase_date: mainFormData?.purchase_date,

      no_spares_toggle: mainFormData?.no_spares_toggle || 0,
      no_pediatric_toggle: mainFormData?.no_pediatric_toggle || 0,
      RMS_toggle: mainFormData?.RMS_toggle || 0,
      out_of_service_toggle: mainFormData?.out_of_service_toggle || 0,

      rms_info: [
        {
          rms_brand: mainFormData?.rms_brand,
          rms_name: mainFormData?.rms_name,
        },
      ],
      loaner_serial_id: mainFormData?.loaner_serial_id,
      out_of_service_info: mainFormData?.out_of_service_toggle
        ? [
          {
            date_sent_to_manufacturer:
              mainFormData?.date_sent_to_manufacturer,
            loaner_rental_serial: mainFormData?.loaner_rental_serial,
            loaner_rental_serial_name:
              mainFormData?.loaner_rental_serial_name,
            reason: mainFormData?.reason,
            not_replacing: mainFormData?.not_replacing,
            loaner_toggle: mainFormData?.loaner_toggle,
            replaced_serial: mainFormData?.replaced_serial,
            replaced_serial_name: mainFormData?.replaced_serial_name,
          },
        ]
        : [],

      // battery_info: [
      //   {
      //     has_battery: mainFormData?.has_battery,
      //     has_9v: mainFormData?.has_9v,
      //     has_installby: mainFormData?.has_installby,
      //     has_man: mainFormData?.has_man,
      //     has_10pk: mainFormData?.has_10pk,
      //   },
      // ],

      battery_info: [
        {
          has_battery: matchAndFilter(mainFormData?.has_battery, "has_battery"),
          has_9v: matchAndFilter(mainFormData?.has_9v, "has_9v"),
          has_installby: matchAndFilter(mainFormData?.has_installby, "has_installby"),
          has_man: matchAndFilter(mainFormData?.has_man, "has_man"),
          has_10pk: matchAndFilter(mainFormData?.has_10pk, "has_10pk"),
        },
      ],

      batteries_expirations: batteries_expirations,
      adult_pad_exp_date: adult_pad_exp_date,
      min_exp_date: "",
      // spare_battery_info: [
      //   {
      //     has_battery_spare: mainFormData?.has_battery_spare,
      //     has_9v_spare: mainFormData?.has_9v_spare,
      //     has_installby_spare: mainFormData?.has_installby_spare,
      //     has_man_spare: mainFormData?.has_man_spare,
      //     has_10pk_spare: mainFormData?.has_10pk_spare,
      //   },
      // ],

      spare_battery_info: [
        {
          has_battery_spare: matchAndFilter(mainFormData?.has_battery_spare, "has_battery_spare"),
          has_9v_spare: matchAndFilter(mainFormData?.has_9v_spare, "has_9v_spare"),
          has_installby_spare: matchAndFilter(mainFormData?.has_installby_spare, "has_installby_spare"),
          has_man_spare: matchAndFilter(mainFormData?.has_man_spare, "has_man_spare"),
          has_10pk_spare: matchAndFilter(mainFormData?.has_10pk_spare, "has_10pk_spare"),
        },
      ],


      storage_info: [
        {
          storage_type: mainFormData?.storage_type,
          storage_part_name: mainFormData?.storage_part_name,
          alarmed: mainFormData?.alarmed,
          alarm_status: mainFormData?.alarm_status,
          v9_Installed_Date: mainFormData?.alarmed
            ? mainFormData?.v9_Installed_Date
            : "",
          expiry_date: mainFormData?.alarmed
            ? mainFormData?.expiry_date
            : "",
          // ? moment(mainFormData?.v9_Installed_Date).add(1, 'year') : "",
        },
      ],

      charge_pak_info: mainFormData?.ChargePakInformation,

      spare_charge_pak_info: mainFormData?.SpareChargePakInformation,

      adult_pad_info: mainFormData?.AdultPadInformation,

      spare_adult_pad_info: mainFormData?.SpareAdultPadInfo,

      adult_pad_pak_info: mainFormData?.AdultPadPakInfo,

      spare_adult_pad_pak_info: mainFormData?.SpareAdultPadPakInfo,

      pediatric_pad_info: mainFormData?.PediatricPadInformation,
      spare_padric_pad_info: mainFormData?.sparePadricPadInfo,
      pediatric_pak_pad_info: mainFormData?.PediatricPakPadInformation,
      spare_padric_pak_pad: mainFormData?.sparePadricPakPad,

      gateway_info: mainFormData?.GatewayInformation,
      builtin_RMS_info: mainFormData?.BuiltInRMSInformation,
      pediatric_key: mainFormData?.pediatric_key,
      last_check: "", // FormatDate(mainFormData?.check_date) removed by Gautam
      unknown_toggle: 0,
      useSbidInventory: mainFormData?.useSbidInventory,
      useSpidInventory: mainFormData?.useSpidInventory,
    };

    sendFormData.unknown_toggle = HasUnknownValue(sendFormData);
    // let res = await CallPOSTAPI("account/edit-aed/" + AEDId, sendFormData);
    // console.log({sendFormData});
    // return;
    let res = await CallPOSTAPI("aed/edit-aed/" + AEDId, sendFormData);
    if (res?.data?.status) {
      setFormMsg({ type: res?.data?.status, msg: res?.data?.msg });
      toast.success("AED added successfully");
      handleEditDispatch()
      navigate(-1);
    } else {
      setFormMsg({ type: 0, msg: "Something went wrong please try again" });
      toast.error("Something went wrong please try again");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (formData?.model_name) {
      fetchAEDPads();
    }
  }, [formData?.model_name]);

  const [aedList, setAedList] = useState([]);
  const [loanerList, setLoanerList] = useState([]);

  const getAllAeds = async () => {
    // const result = await CallGETAPI("account/get-aed/" + currentAccountId);
    const result = await CallGETAPI(
      "account/get-aed-with-standalon/" + currentAccountId
    );
    if (result?.data?.status) {
      let aeds = result?.data?.data || [];
      const pendingaeds = result?.data?.pendingData;
      let newArr = [];

      if (Array.isArray(aeds) && pendingaeds.length > 0) {
        newArr = [...pendingaeds, ...aeds];
      } else {
        newArr = aeds;
      }
      let currentList = [];

      for (let i = 0; i < newArr.length; i++) {
        for (let j = 0; j < newArr[i].data.length; j++) {
          currentList.push(newArr[i].data[j]);
        }
      }

      // aeds = newArr;
      setAedList(currentList);
    }
  };

  const getLoanerList = async () => {
    // const result = await CallGETAPI("account/get-ross-aed");
    const result = await CallGETAPI(
      `account/get-rental-serial/${formData?.site_id}`
    );

    if (result?.data?.status) {
      setLoanerList(result?.data?.serialNumbers);
    }
    setFormData((prev) => ({
      ...prev,
      loaner_rental_serial: "",
      loaner_serial_id: "",
    }));
  };

  // useEffect(() => {
  //   getLoanerList();
  // },[formData?.site_id])

  // useEffect(() => {
  //   if (currentAccountId) {
  //     getAllAeds();
  //     getLoanerList();
  //   }
  // }, [currentAccountId]);

  useEffect(() => { }, [showAddRow]);

  return (
    <>
      {/* loading */}
      {showLoading ? (
        <>
          <div className="showloading">
            <Loading />
          </div>
        </>
      ) : (
        <>
          <div className="mt-4" style={{ paddingInline: "45px" }}>
            <form class="" onSubmit={handleSubmit}>
              <SubHeading
                title="Edit AED"
                subHeading={true}
                hideNew={true}
                hideHierarchy={true}
                bottomLinks={false}
              />
              <EditAedForm
                AccountList={AccLidatData}
                BrandList={brandData}
                formData={formData}
                setFormData={setFormData}
                setPermission={setPermission}
                Permissins={Permissins}
                RmsDropdown={RmsDropdown}
                all_condition_true={all_condition_true}
                is_edit={true}
                aedList={aedList}
                loanerList={loanerList}
                siteId={siteId}
              />

              <EditParentBatteryInfo
                formData={formData}
                setFormData={setFormData}
                handleCheckBox={handleCheckBox}
                handleInput={handleInput}
                addMore={addRemoveChargePakInfo}
                Permissins={Permissins}
                addRemoveBtn={addRemoveChargePakInfo}
                all_condition_true={all_condition_true}
                BatteryList={BatteryList}
                is_edit={true}
                showAddRow={showAddRow}
                setShowAddRow={setShowAddRow}
                forResetData={forResetData}
              />

              {Permissins?.has_chargepak || all_condition_true ? (
                <div
                  className=" bg-gray py-4 px-4 mt-4"
                  id="charge_pak_information"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h2 className="heading">
                      {"Charge Pak Information"}
                      <button
                        onClick={() =>
                          addRemoveChargePakInfo(
                            "add",
                            "SpareChargePakInformation"
                          )
                        }
                        className="btn mx-2 btn-sm btn-primary "
                        disabled={!formData?.no_spares_toggle}
                        type="button"
                      >
                        +
                      </button>
                    </h2>

                    {(Number(showAddRow?.ChargePakInformation) === 1 || Number(PermissionRedux?.has_chargepak) === 1) ? (
                      <AddMainRowBtn
                        sectionName={"has_chargepak"}
                        formData={formData}
                        setFormData={setFormData}
                        setShowAddRow={setShowAddRow}
                        formDataKey={"ChargePakInformation"}
                      />
                    ) : (
                      " "
                    )}
                    {/* <div className="toggle">
                      <label className="d-block mb-2">
                        <b>Unknown</b>
                      </label>
                      <CustomToggleButton
                        ToggleName="no_has_chargepak_toggle"
                        // ToggleValue={formData?.no_has_chargepak_toggle}
                        ToggleValue={
                          formData?.ChargePakInformation[0]?.pad_type_id ===
                            "unknown"
                            ? true
                            : false
                        }
                        changeHandler={handleCheckBox}
                      />
                    </div> */}
                  </div>
                  {formData?.ChargePakInformation?.map((item, index) => (
                    <EditChargePakInfo
                      is_unknowntrue={
                        item.pad_type_id === "unknown" ? true : false
                      }
                      keyName={"ChargePakInformation"}
                      unKnownToggleKey={"no_has_chargepak_toggle"}
                      title="Battery Information"
                      crrIndex={index}
                      formData={formData}
                      setFormData={setFormData}
                      handleCheckBox={handleCheckBox}
                      handleInput={handleInput}
                      crrFormData={item}
                      addMore={addMorebattery_info}
                      BatteryList={BatteryList}
                      padList={padList}
                      all_condition_true={all_condition_true}
                      is_edit={true}
                      showAddRow={showAddRow}
                      setShowAddRow={setShowAddRow}
                    />
                  ))}
                </div>
              ) : (
                ""
              )}

              {(Permissins?.has_chargepak &&
                formData?.SpareChargePakInformation.length > 0) ||
                all_condition_true ? (
                <div
                  className=" bg-gray py-4 px-4 mt-4"
                  id="spare_charge_pak_information"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h2 className="heading">
                      {"Spare Charge Pak Information"}

                      <button
                        onClick={() =>
                          addRemoveChargePakInfo(
                            "add",
                            "SpareChargePakInformation"
                          )
                        }
                        className="btn mx-2 btn-sm btn-primary "
                        disabled={!formData?.no_spares_toggle}
                        type="button"
                      >
                        +
                      </button>
                      {/* <button
                        onClick={() =>
                          addRemoveChargePakInfo(
                            "remove",
                            "SpareChargePakInformation"
                          )
                        }
                        className="btn mx-2 btn-sm btn-danger "
                        type="button"
                      >
                        -
                      </button> */}
                    </h2>
                    {/* <div className="toggle">
                      <label className="d-block mb-2">
                        <b>Unknown</b>
                      </label>
                      <CustomToggleButton
                        ToggleName="no_spare_charge_pak_info_toggle"
                        ToggleValue={
                          formData?.SpareChargePakInformation[0]?.pad_type_id ===
                            "unknown"
                            ? true
                            : false
                        }
                        changeHandler={handleCheckBox}
                      />
                    </div> */}
                  </div>

                  {formData?.SpareChargePakInformation?.map((item, index) => (
                    <EditSpareChargePakInfo
                      keyName={"SpareChargePakInformation"}
                      toogleKeyName={formData?.no_spare_charge_pak_info_toggle}
                      is_unknowntrue={
                        item.pad_type_id === "unknown" ? true : false
                      }
                      title="Battery Information"
                      unKnownToggleKey={"no_spare_charge_pak_info_toggle"}
                      crrIndex={index}
                      formData={formData}
                      setFormData={setFormData}
                      handleCheckBox={handleCheckBox}
                      handleInput={handleInput}
                      crrFormData={item}
                      addMore={addMorebattery_info}
                      BatteryList={BatteryList}
                      padList={padList}
                      all_condition_true={all_condition_true}
                      is_edit={1}
                    />
                  ))}
                </div>
              ) : (
                ""
              )}

              {Permissins?.has_pad || all_condition_true ? (
                <div
                  className=" bg-gray py-4 px-4 mt-4"
                  id="adult_pad_information"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h2 className="heading">
                      {"Adult Pad Information"}
                      <button
                        onClick={() =>
                          addRemoveChargePakInfo("add", "SpareAdultPadInfo")
                        }
                        className="btn mx-2 btn-sm btn-primary "
                        disabled={!formData?.no_spares_toggle}
                        type="button"
                      >
                        +
                      </button>
                    </h2>
                    {/* {JSON?.stringify({ showAddRow })} */}
                    {(Number(showAddRow?.AdultPadInformation) === 1 || Number(PermissionRedux?.has_pad) === 1) ? (
                      <AddMainRowBtn
                        sectionName={"has_pad"}
                        formData={formData}
                        setFormData={setFormData}
                        setShowAddRow={setShowAddRow}
                        formDataKey={"AdultPadInformation"}
                      />
                    ) : (
                      " "
                    )}

                    {/* <div className="toggle">
                      <label className="d-block mb-2">
                        <b>Unknown</b>
                      </label>
                      <CustomToggleButton
                        ToggleName="no_has_pad_toggle"
                        ToggleValue={
                          formData?.AdultPadInformation[0]?.pad_type_id ===
                            "unknown"
                            ? true
                            : false
                        }
                        changeHandler={handleCheckBox}
                      />
                    </div> */}
                  </div>

                  {/* { formData?.AdultPadInformation */}
                  {formData?.AdultPadInformation?.map((item, index) => (
                    <EditAdultPakInfo
                      keyName={"AdultPadInformation"}
                      unKnownToggleKey={"no_has_pad_toggle"}
                      toogleKeyName={formData?.no_has_pad_toggle}
                      is_unknowntrue={
                        item.pad_type_id === "unknown" ? true : false
                      }
                      title="Battery Information"
                      crrIndex={index}
                      formData={formData}
                      setFormData={setFormData}
                      handleCheckBox={handleCheckBox}
                      handleInput={handleInput}
                      crrFormData={item}
                      addMore={addMorebattery_info}
                      padList={padList}
                      showAddRow={showAddRow}
                      setShowAddRow={setShowAddRow}
                    />
                  ))}
                </div>
              ) : (
                ""
              )}

              {(Permissins?.has_pad &&
                formData?.SpareAdultPadInfo.length > 0) ||
                all_condition_true ? (
                <div
                  className=" bg-gray py-4 px-4 mt-4"
                  id="spare_adult_pad_information"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h2 className="heading">
                      {"Spare Adult Pad Information"}
                      <button
                        onClick={() =>
                          addRemoveChargePakInfo("add", "SpareAdultPadInfo")
                        }
                        className="btn mx-2 btn-sm btn-primary "
                        disabled={!formData?.no_spares_toggle}
                        type="button"
                      >
                        +
                      </button>
                      {/* <button
                        onClick={() =>
                          addRemoveChargePakInfo("remove", "SpareAdultPadInfo")
                        }
                        className="btn mx-2 btn-sm btn-danger "
                        type="button"
                      >
                        -
                      </button> */}
                    </h2>
                    {/* <div className="toggle">
                      <label className="d-block mb-2">
                        <b>Unknown</b>
                      </label>
                      <CustomToggleButton
                        ToggleName="no_spare_adult_pak_info_toggle"
                        ToggleValue={
                          formData?.SpareAdultPadInfo[0]?.pad_type_id ===
                            "unknown"
                            ? true
                            : false
                        }
                        changeHandler={handleCheckBox}
                      />
                    </div> */}
                  </div>

                  {formData?.SpareAdultPadInfo?.map((item, index) => (
                    <EditSpareAdultPadInfo
                      keyName={"SpareAdultPadInfo"}
                      unKnownToggleKey={"no_spare_adult_pak_info_toggle"}
                      toogleKeyName={formData?.no_spare_adult_pak_info_toggle}
                      is_unknowntrue={
                        item.pad_type_id === "unknown" ? true : false
                      }
                      title="Battery Information"
                      crrIndex={index}
                      formData={formData}
                      setFormData={setFormData}
                      handleCheckBox={handleCheckBox}
                      handleInput={handleInput}
                      crrFormData={item}
                      addMore={addMorebattery_info}
                      padList={padList}
                    />
                  ))}
                </div>
              ) : (
                ""
              )}
              {/*  */}

              {/* Adult Pad Pak Info */}
              {Permissins?.has_padpak || all_condition_true ? (
                <div
                  className=" bg-gray py-4 px-4 mt-4"
                  id="adult_pad_pak_information"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h2 className="heading">
                      {"Adult Pad Pak Information"}
                      <button
                        onClick={() =>
                          addRemoveChargePakInfo("add", "SpareAdultPadPakInfo")
                        }
                        className="btn mx-2 btn-sm btn-primary "
                        disabled={!formData?.no_spares_toggle}
                        type="button"
                      >
                        +
                      </button>
                    </h2>

                    {/* {JSON?.stringify({ showAddRow })} */}
                    {(Number(showAddRow?.AdultPadPakInfo) === 1 || Number(PermissionRedux?.has_padpak) === 1) ? (
                      <AddMainRowBtn
                        sectionName={"has_padpak"}
                        formData={formData}
                        setFormData={setFormData}
                        setShowAddRow={setShowAddRow}
                        formDataKey={"AdultPadPakInfo"}
                      />
                    ) : (
                      " "
                    )}
                    {/* <div className="toggle">
                      <label className="d-block mb-2">
                        <b>Unknown </b>
                      </label>
                      <CustomToggleButton
                        ToggleName="no_has_padpak_toggle"
                        ToggleValue={
                          formData?.AdultPadPakInfo[0]?.pad_type_id ===
                            "unknown"
                            ? true
                            : false
                        }
                        changeHandler={handleCheckBox}
                      />
                    </div> */}
                  </div>

                  {formData?.AdultPadPakInfo?.map((item, index) => (
                    <EditAdultPadPakInfo
                      keyName={"AdultPadPakInfo"}
                      unKnownToggleKey={"no_has_padpak_toggle"}
                      toogleKeyName={formData?.no_has_padpak_toggle}
                      is_unknowntrue={
                        item.pad_type_id === "unknown" ? true : false
                      }
                      title="Battery Information"
                      crrIndex={index}
                      formData={formData}
                      setFormData={setFormData}
                      handleCheckBox={handleCheckBox}
                      handleInput={handleInput}
                      crrFormData={item}
                      addMore={addMorebattery_info}
                      padList={padList}
                      showAddRow={showAddRow}
                      setShowAddRow={setShowAddRow}
                    />
                  ))}
                </div>
              ) : (
                ""
              )}

              {(Permissins?.has_padpak &&
                formData?.SpareAdultPadPakInfo.length > 0) ||
                all_condition_true ? (
                <div
                  className=" bg-gray py-4 px-4 mt-4"
                  id="spare_adult_pad_pak_information"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h2 className="heading">
                      {"Spare Adult Pad Pak Information"}
                      <button
                        onClick={() =>
                          addRemoveChargePakInfo("add", "SpareAdultPadPakInfo")
                        }
                        className="btn mx-2 btn-sm btn-primary "
                        disabled={!formData?.no_spares_toggle}
                        type="button"
                      >
                        +
                      </button>
                      {/* <button
                        onClick={() =>
                          addRemoveChargePakInfo(
                            "remove",
                            "SpareAdultPadPakInfo"
                          )
                        }
                        className="btn mx-2 btn-sm btn-danger "
                        type="button"
                      >
                        -
                      </button> */}
                    </h2>
                    {/* <div className="toggle">
                      <label className="d-block mb-2">
                        <b>Unknown</b>
                      </label>
                      <CustomToggleButton
                        ToggleName="no_spare_adult_pad_pak_info_toggle"
                        ToggleValue={
                          formData?.SpareAdultPadPakInfo[0]?.pad_type_id ===
                            "unknown"
                            ? true
                            : false
                        }
                        changeHandler={handleCheckBox}
                      />
                    </div> */}
                  </div>

                  {formData?.SpareAdultPadPakInfo?.map((item, index) => (
                    <EditSpareAdultPadPakInfoComp
                      is_unknowntrue={
                        item.pad_type_id === "unknown" ? true : false
                      }
                      keyName={"SpareAdultPadPakInfo"}
                      unKnownToggleKey={"no_spare_adult_pad_pak_info_toggle"}
                      title="SpareAdultPadPakInfo"
                      crrIndex={index}
                      formData={formData}
                      setFormData={setFormData}
                      handleCheckBox={handleCheckBox}
                      handleInput={handleInput}
                      crrFormData={item}
                      addMore={addMorebattery_info}
                      padList={padList}
                    />
                  ))}
                </div>
              ) : (
                ""
              )}
              {(formData.no_pediatric_toggle && Permissins?.has_ped_pad) ||
                all_condition_true ? (
                <div
                  className=" bg-gray py-4 px-4 mt-4"
                  id="pediatric_pad_information"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h2 className="heading">
                      {"Pediatric Pad Information"}

                      <button
                        onClick={() =>
                          addRemoveChargePakInfo("add", "sparePadricPadInfo")
                        }
                        className="btn mx-2 btn-sm btn-primary "
                        disabled={!formData?.no_spares_toggle}
                        type="button"
                      >
                        +
                      </button>
                    </h2>

                    {/* {JSON?.stringify({ showAddRow })} */}
                    {(Number(showAddRow?.PediatricPadInformation) === 1 || Number(PermissionRedux?.has_ped_pad) === 1) ? (
                      <AddMainRowBtn
                        sectionName={"has_ped_pad"}
                        formData={formData}
                        setFormData={setFormData}
                        setShowAddRow={setShowAddRow}
                        formDataKey={"PediatricPadInformation"}
                      />
                    ) : (
                      " "
                    )}
                    {/* <div className="toggle">
                      <label className="d-block mb-2">
                        <b>Unknown</b>
                      </label>
                      <CustomToggleButton
                        ToggleName="no_has_pedpad_toggle"
                        ToggleValue={
                          formData?.PediatricPadInformation[0]?.pad_type_id ===
                            "unknown"
                            ? true
                            : false
                        }
                        changeHandler={handleCheckBox}
                      />
                    </div> */}
                  </div>

                  {formData?.PediatricPadInformation?.map((item, index) => (
                    <EditPediatricPadInfo
                      unKnownToggleKey={"no_has_pedpad_toggle"}
                      // is_unknowntrue={formData?.no_has_pedpad_toggle}
                      is_unknowntrue={
                        item.pad_type_id === "unknown" ? true : false
                      }
                      keyName={"PediatricPadInformation"}
                      title="Pediatric Pad Info"
                      crrIndex={index}
                      formData={formData}
                      setFormData={setFormData}
                      handleCheckBox={handleCheckBox}
                      handleInput={handleInput}
                      crrFormData={item}
                      addMore={addMorebattery_info}
                      padList={padList}
                      showAddRow={showAddRow}
                      setShowAddRow={setShowAddRow}
                    />
                  ))}
                </div>
              ) : (
                ""
              )}

              {/* <h1>{JSON.stringify(formData.no_pediatric_toggle)}</h1> */}
              {(formData.no_pediatric_toggle &&
                Permissins?.has_ped_pad &&
                formData?.sparePadricPadInfo.length > 0) ||
                all_condition_true ? (
                <div
                  className=" bg-gray py-4 px-4 mt-4"
                  id="spare_pediatric_pad_information"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h2 className="heading">
                      {"Spare Pediatric Pad Information"}
                      <button
                        onClick={() =>
                          addRemoveChargePakInfo("add", "sparePadricPadInfo")
                        }
                        className="btn mx-2 btn-sm btn-primary "
                        disabled={!formData?.no_spares_toggle}
                        type="button"
                      >
                        +
                      </button>
                      {/* <button
                        onClick={() =>
                          addRemoveChargePakInfo("remove", "sparePadricPadInfo")
                        }
                        className="btn mx-2 btn-sm btn-danger "
                        type="button"
                      >
                        -
                      </button> */}
                    </h2>

                    {/* <div className="toggle">
                      <label className="d-block mb-2">
                        <b>Unknown</b>
                      </label>
                      <CustomToggleButton
                        ToggleName="no_spare_padric_pad_info_toggle"
                        ToggleValue={
                          formData?.sparePadricPadInfo[0]?.pad_type_id ===
                            "unknown"
                            ? true
                            : false
                        }
                        changeHandler={handleCheckBox}
                      />
                    </div> */}
                  </div>

                  {formData?.sparePadricPadInfo?.map((item, index) => (
                    <EditSparePediatricPadInfo
                      unKnownToggleKey={"no_spare_padric_pad_info_toggle"}
                      // is_unknowntrue={formData?.no_spare_padric_pad_info_toggle}
                      is_unknowntrue={
                        item.pad_type_id === "unknown" ? true : false
                      }
                      keyName={"sparePadricPadInfo"}
                      title="sparePadricPadInfo"
                      crrIndex={index}
                      formData={formData}
                      setFormData={setFormData}
                      handleCheckBox={handleCheckBox}
                      handleInput={handleInput}
                      crrFormData={item}
                      padList={padList}
                      addMore={addMorebattery_info}
                    />
                  ))}
                </div>
              ) : (
                ""
              )}

              {(formData.no_pediatric_toggle && Permissins?.has_padpak) ||
                all_condition_true ? (
                <div
                  className=" bg-gray py-4 px-4 mt-4"
                  id="pediatric_pad_pak_information"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h2 className="heading">
                      {"Pediatric Pad-Pak Information"}

                      <button
                        onClick={() =>
                          addRemoveChargePakInfo("add", "sparePadricPakPad")
                        }
                        className="btn mx-2 btn-sm btn-primary "
                        disabled={!formData?.no_spares_toggle}
                        type="button"
                      >
                        +
                      </button>
                    </h2>

                    {/* {JSON?.stringify({ showAddRow })} */}
                    {(Number(showAddRow?.PediatricPakPadInformation) === 1 || Number(PermissionRedux?.has_padpak) === 1) ? (
                      <AddMainRowBtn
                        sectionName={"has_padpak"}
                        formData={formData}
                        setFormData={setFormData}
                        setShowAddRow={setShowAddRow}
                        formDataKey={"PediatricPakPadInformation"}
                      />
                    ) : (
                      " "
                    )}
                    {/* <div className="toggle">
                      <label className="d-block mb-2">
                        <b>Unknown</b>
                      </label>
                      <CustomToggleButton
                        ToggleName="no_has_pedpak_toggle"
                        ToggleValue={
                          formData?.PediatricPakPadInformation[0]?.pad_type_id ===
                            "unknown"
                            ? true
                            : false
                        }
                        changeHandler={handleCheckBox}
                      />
                    </div> */}
                  </div>

                  {formData?.PediatricPakPadInformation?.map((item, index) => (
                    <EditPediatricPadPakInfo
                      unKnownToggleKey={"no_has_pedpak_toggle"}
                      // is_unknowntrue={formData?.no_has_pedpak_toggle}
                      is_unknowntrue={
                        item.pad_type_id === "unknown" ? true : false
                      }
                      keyName={"PediatricPakPadInformation"}
                      title="Pediatric Pad Info"
                      crrIndex={index}
                      formData={formData}
                      setFormData={setFormData}
                      handleCheckBox={handleCheckBox}
                      handleInput={handleInput}
                      crrFormData={item}
                      padList={padList}
                      addMore={addMorebattery_info}
                      showAddRow={showAddRow}
                      setShowAddRow={setShowAddRow}
                    />
                  ))}
                </div>
              ) : (
                ""
              )}

              {Permissins?.has_padpak &&
                formData?.sparePadricPakPad.length > 0 ? (
                <div
                  className=" bg-gray py-4 px-4 mt-4"
                  id="spare_pediatric_pak_pad_information"
                >
                  <div className="d-flex align-items-center justify-content-between">
                    <h2 className="heading">
                      {"Spare Pediatric Pak-Pad Information"}
                      <button
                        onClick={() =>
                          addRemoveChargePakInfo("add", "sparePadricPakPad")
                        }
                        className="btn mx-2 btn-sm btn-primary "
                        disabled={!formData?.no_spares_toggle}
                        type="button"
                      >
                        +
                      </button>
                      {/* <button
                        onClick={() =>
                          addRemoveChargePakInfo("remove", "sparePadricPakPad")
                        }
                        className="btn mx-2 btn-sm btn-danger "
                        type="button"
                      >
                        -
                      </button> */}
                    </h2>
                    {/* <div className="toggle">
                      <label className="d-block mb-2">
                        <b>Unknown</b>
                      </label>
                      <CustomToggleButton
                        ToggleName="no_spare_padric_pad_pak_info_toggle"
                        ToggleValue={
                          formData?.sparePadricPakPad[0]?.pad_type_id ===
                            "unknown"
                            ? true
                            : false
                        }
                        changeHandler={handleCheckBox}
                      />
                    </div> */}
                  </div>

                  {formData?.sparePadricPakPad?.map((item, index) => (
                    <EditSparePediatricPadPakInfo
                      unKnownToggleKey={"no_spare_padric_pad_pak_info_toggle"}
                      // is_unknowntrue={formData?.no_spare_padric_pad_pak_info_toggle}
                      is_unknowntrue={
                        item.pad_type_id === "unknown" ? true : false
                      }
                      keyName={"sparePadricPakPad"}
                      title="sparePadricPakPad"
                      crrIndex={index}
                      formData={formData}
                      setFormData={setFormData}
                      handleCheckBox={handleCheckBox}
                      handleInput={handleInput}
                      crrFormData={item}
                      addMore={addMorebattery_info}
                      padList={padList}
                    />
                  ))}
                </div>
              ) : (
                ""
              )}

              {Permissins?.has_ped_key || all_condition_true ? (
                <div
                  className=" bg-gray py-4 px-4 mt-4"
                  id="pediatric_key_information"
                >
                  <h2 className="heading">{"Pediatric Key Information"} </h2>
                  <EditPediatricKeyInfo
                    keyName={"GatewayInformation"}
                    title="GatewayInformation"
                    crrIndex={"1"}
                    formData={formData}
                    setFormData={setFormData}
                    handleCheckBox={handleCheckBox}
                    handleInput={handleInput}
                    addMore={addMorebattery_info}
                  />
                </div>
              ) : (
                ""
              )}

              {/* PediatricKeyInfo */}
              {Permissins?.has_gateway || all_condition_true ? (
                <div
                  className=" bg-gray py-4 px-4 mt-4"
                  id="gateway_information"
                >
                  <h2 className="heading">{"Gateway Information"} </h2>

                  {formData?.GatewayInformation?.map((item, index) => (
                    <EditGatewayInformation
                      keyName={"GatewayInformation"}
                      title="GatewayInformation"
                      crrIndex={index}
                      formData={formData}
                      setFormData={setFormData}
                      handleCheckBox={handleCheckBox}
                      handleInput={handleInput}
                      crrFormData={item}
                      addMore={addMorebattery_info}
                    />
                  ))}
                </div>
              ) : (
                ""
              )}

              {Permissins?.has_builtin_rms || all_condition_true ? (
                <div
                  className=" bg-gray py-4 px-4 mt-4"
                  id="built_in_RMS_information"
                >
                  <h2 className="heading">{"Built-In RMS Information"} </h2>

                  {formData?.BuiltInRMSInformation?.map((item, index) => (
                    <EditBuiltInRMSInformation
                      keyName={"BuiltInRMSInformation"}
                      title="BuiltInRMSInformation"
                      crrIndex={index}
                      formData={formData}
                      setFormData={setFormData}
                      handleCheckBox={handleCheckBox}
                      handleInput={handleInput}
                      crrFormData={item}
                      addMore={addMorebattery_info}
                    />
                  ))}
                </div>
              ) : (
                ""
              )}

              <div className="col-12 mt-3">
                <MessageHandler
                  status={FormMsg.type}
                  msg={FormMsg.msg}
                  HandleMessage={setFormMsg}
                />
              </div>

              <div class="col-md-12 text-right mt-3">
                <button
                  class="btn btn-danger me-2"
                  type="button"
                  onClick={() => {
                    handleEditDispatch()
                    navigate(-1)
                  }}


                >
                  Cancel
                </button>
                <button
                  class="btn btn-success"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </button>
              </div>
            </form>

        {/* Overlay */}
        {loading && (
          <div style={overlay}>
            <Spinner style={{ width: "3rem", height: "3rem" }}/>
          </div>
          )}

            <br />
            <br />
            <br />
          </div>
        </>
      )}
    </>
  );
};
export default EditAed;