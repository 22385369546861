import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  breadcrumbs: [], // Array of breadcrumb objects
};




const breadcrumbSlice = createSlice({
  name: "breadcrumbs",
  initialState,
  reducers: {
    addNewBreadcrumbs: (state, action) => {
      const { title, url } = action.payload;
      const tab = action.payload.tab || "";
      const type = action.payload.type || "";
      const accountName = action.payload.account_Name || "";
      const accountId = action.payload.accountId || "";
      const urlParts = url.split('/');
      const lastPartOfUrl = urlParts[urlParts.length - 1];
      const existingBreadcrumbIndex = state.breadcrumbs ? state.breadcrumbs.findIndex(
        (breadcrumb) => breadcrumb.url === url
      ) : -1;
      if (existingBreadcrumbIndex === -1) {
        if (lastPartOfUrl === "Details") {
          state.breadcrumbs.push({ title, url, tab, type, accountName, accountId });
        } else {

          const similarBreadcrumbIndex = state.breadcrumbs.findIndex((breadcrumb) => {
            const breadcrumbUrlParts = breadcrumb.url.split('/');
            const lastPartOfBreadcrumbUrl = breadcrumbUrlParts[breadcrumbUrlParts.length - 1];
            return breadcrumbUrlParts.slice(0, -1).join('/') === urlParts.slice(0, -1).join('/')
              && lastPartOfBreadcrumbUrl !== "Details";
          });

          if (similarBreadcrumbIndex !== -1) {
            state.breadcrumbs[similarBreadcrumbIndex] = { title, url, tab, type, accountName, accountId };
          } else {
            state.breadcrumbs.push({ title, url, tab, type, accountName, accountId });
          }
        }
      } else {
        console.log({
          existingBreadcrumbIndex,
          data: state.breadcrumbs,
          checkHere: { ...state.breadcrumbs[existingBreadcrumbIndex], title, tab, type }
        });

        state.breadcrumbs = state.breadcrumbs.slice(0, existingBreadcrumbIndex + 1);
      }
    },

    updateBreadcrumbs: (state, action) => {
      state.breadcrumbs = action.payload;
    },
    handleBreadcrumbsClick: (state, action) => {
      const { accountName, breadcrumbsFromStore } = action.payload;
      function areStringsEqual(str1, str2) {
        console.log({ str1, str2 });

        function cleanString(str) {
          return str
            .trim()               // Remove leading and trailing spaces
            .replace(/\s+/g, ''); // Remove all spaces (not just extra spaces between words)
        }

        const cleanedStr1 = cleanString(str1);
        const cleanedStr2 = cleanString(str2);

        return cleanedStr1 === cleanedStr2;
      }

      // Example usage
      const name = accountName
      const result = areStringsEqual(' Aarav TC Testing Contact  Details', name + " Details");

      console.log({ result });  // true





      const detailsIndex = breadcrumbsFromStore.findIndex(breadcrumb => areStringsEqual(breadcrumb.title, name + " Details"))
      const index = detailsIndex;
      console.log({ accountName, breadcrumbsFromStore, detailsIndex })
      if (detailsIndex !== -1 && detailsIndex < breadcrumbsFromStore.length - 1) {
        state.breadcrumbs = state.breadcrumbs.slice(0, index + 1);
      }
    },

    // Remove a specific breadcrumb by its URL
    removeBreadcrumb: (state, action) => {
      const urlToRemove = action.payload;

      state.breadcrumbs = state.breadcrumbs.filter(
        (breadcrumb) => breadcrumb.url !== urlToRemove
      );

    },

    // Clear all breadcrumbs
    clearBreadcrumbs: (state) => {
      state.breadcrumbs = [];
    },
  },
});

export const {
  addNewBreadcrumbs,
  updateBreadcrumbs,
  handleBreadcrumbsClick,
  removeBreadcrumb,
  clearBreadcrumbs,

} = breadcrumbSlice.actions;

export default breadcrumbSlice.reducer;