import React, { useEffect, useState } from "react";
import { CallGETAPI } from "../../../../common/services";

const AedsDistribution = () => {
  const [aedsDistribution, setAedsDistribution] = useState();

  const fetchOnLoad = async () => {
    const response = await CallGETAPI("user/user-aed-sites");
    setAedsDistribution(response?.data?.data);
    console.log({ response });
  };

  useEffect(() => {
    fetchOnLoad();
  }, []);

  return (
    <>
      <div>
        {aedsDistribution &&
          aedsDistribution.map((aedDistribution) => (
            <>
              <div style={{maxWidth: "300px"}}>
                {aedDistribution?.site_name ? aedDistribution?.site_name : ""}
              </div>
            </>
          ))}
      </div>
    </>
  );
};

export default AedsDistribution;
