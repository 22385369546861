import {
  faCaretDown,
  faChartBar,
  faChartLine,
  faCog,
  faEnvelope,
  faHome,
  faListAlt,
  faSignOutAlt,
  faUserPlus,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Nav, Offcanvas } from "react-bootstrap";
import { HandleLogout } from "../../helper/BasicFn";
import { Link, useNavigate } from "react-router-dom";
import PermissionManagerMenu from "../../../domains/permissionManager/pages/PermissionManagerMenu";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// Custom Dropdown component for sidebar
// const SidebarDropdown = ({ title, icon, children, isOpen, onToggle }) => {
//   return (
//     <Nav.Item>
//       <Nav.Link onClick={onToggle} className="sidebar-dropdown-toggle">
//         <FontAwesomeIcon icon={icon} className="me-2" />
//         {title} &nbsp;{" "}
//         <FontAwesomeIcon
//           icon={faCaretDown}
//           className={`ms-auto ${isOpen ? "rotate-icon" : ""}`}
//         />
//       </Nav.Link>
//       <div
//         className={`sidebar-dropdown   ${isOpen ? "show" : "none"}   `}
//         style={{ display: isOpen ? "block" : "none" }}
//       >
//         <Nav className="flex-column">{children}</Nav>
//       </div>
//     </Nav.Item>
//   );
// };

const Sidebar = ({ show, setShow, handleClose }) => {
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prevState) =>
      prevState === dropdownName ? null : dropdownName
    );
  };
  const navigate = useNavigate();
  const handleLogoutClick = async () => {
    await HandleLogout();
  };

  return (
    <>
      <Offcanvas
        show={show}
        onHide={handleClose}
        style={{ width: "300px", background: "#000" }}
        id="sidebar-menus"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title className="text-white">Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <span
              className="nav-link"
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/accounts-listing");
              }}
            >
              {/* <FontAwesomeIcon icon={faListAlt} className="me-2" /> */}
              Accounts
            </span>
            <Link to="/Admin/Sites-listing" className="nav-link">
              {/* <FontAwesomeIcon icon={faListAlt} className="me-2" /> */}
              Site Listing
            </Link>
            <Link to="/Admin/Contact-listing" className="nav-link">
              {/* <FontAwesomeIcon icon={faListAlt} className="me-2" /> */}
              Contact Listing
            </Link>
            <Link to="/Admin/accessory-listing" className="nav-link">
              {/* <FontAwesomeIcon icon={faListAlt} className="me-2" /> */}
              Accessory Listing
            </Link>
            <Link to="/account/support-listing" className="nav-link">
              {/* <FontAwesomeIcon icon={faListAlt} className="me-2" /> */}
              Support Listing
            </Link>
            {/* <Nav.Item> */}
          </Nav>

          <button to="" className="nav-link">
            <PermissionManagerMenu
              setOpenDropdown={setOpenDropdown}
              show={show}
              setShow={setShow}
            />
          </button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default Sidebar;
