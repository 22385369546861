import React, { useState, useEffect } from "react";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import moment from "moment";
import { sortArrAscending } from "../../../common/helper/constants";
import NewGlobalTable from "../../../common/components/custom-ui/NewGlobalTable";
import UpdateRoleStatus from "../component/UpdateRoleStatus";
import EditAssignCreateModal from "../Model/EditAssignCreateModal";
import { truncateText } from "../../../common/helper/BasicFn";
import CreateRoleForm from "../component/CreateRoleForm";

const CreateRole = () => {
  const [edit, setEdit] = useState(false);
  const [rowData, setRowData] = useState("");
  const [updateModal, setUpdateModal] = useState(false);
  const [roleId, setRoleId] = useState("");
  const [active, setActive] = useState("");
  const [tableReset, setTablReset] = useState(false);

  const fetchCreateTblData = async () => {
    setTablReset(true);
    setTimeout(() => {
      setTablReset(false);
    }, 1000);
  };

  const HandleActivate = async (data) => {
    setActive(data?.active);
    setRoleId(data?.role_id);
    setUpdateModal(true);
  };

  const RenderIsAdmin = (data) => {
    return (
      <div>
        {data?.is_admin_role ? (
          <>
            <DoneIcon style={{ color: "green", height: 30, width: 30 }} />
          </>
        ) : (
          <>
            <CloseIcon style={{ color: "red", height: 30, width: 30 }} />
          </>
        )}
      </div>
    );
  };

  const renderRoleType = (data) => {
    return (
      <div>
        {data.role_type === "account_level"
          ? "Account Level"
          : data.role_type === "site_level"
          ? "Site Level"
          : data.role_type === "enhancement"
          ? "Enhancement"
          : ""}
      </div>
    );
  };

  const headerColumns = [
    {
      Header: "Role Type",
      accessor: "role_type", // Maps to the "role_type" field in your data
      width: "10%", // Specify width
      Cell: ({ row }) => renderRoleType(row.original), // Custom render function
    },
    {
      Header: "Role Name",
      accessor: "role_name", // Maps to the "role_name" field
      width: "12%",
      Cell: ({ row }) => truncateText(row.original.role_name, 20),
    },
    {
      Header: "Role",
      accessor: "permissions", // Maps to the "permissions" field
      width: 300, // Specify column width
      sortType: "basic", // Enable sorting
      Cell: ({ row }) => {
        const permissions = row.original.permissions.split(",");
        return (
          <div>
            {permissions.map((account, index) => (
              <div key={index}>{account.trim()}</div>
            ))}
          </div>
        );
      },
    },
    {
      Header: "Admin Role",
      accessor: "is_admin_role", // Maps to the "is_admin_role" field
      width: 120, // Specify width
      sortType: "basic", // Enable sorting
      Cell: ({ row }) => RenderIsAdmin(row.original), // Custom render function
    },
    {
      Header: "Created Date",
      accessor: "created_date", // Maps to the "created_date" field
      sortType: "basic", // Enable sorting
      Cell: ({ row }) =>
        moment(row.original.created_date).isValid()
          ? moment(row.original.created_date).format("MM/DD/YYYY h:mm:ss")
          : "", // Custom cell rendering for date formatting
    },
    {
      Header: "Modified Date",
      accessor: "modify_date", // Maps to the "modify_date" field
      sortType: "basic", // Enable sorting
      Cell: ({ row }) =>
        moment(row.original.modify_date).isValid()
          ? moment(row.original.modify_date).format("MM/DD/YYYY h:mm:ss")
          : "", // Custom cell rendering for date formatting
    },
    {
      Header: "Status",
      accessor: "active", // Maps to the "active" field
      sortType: "basic", // Enable sorting
      Cell: ({ row }) => (
        <div
          style={{
            flexDirection: "row",
            alignItems: "center",
            gap: "7px",
          }}
        >
          {row.original.active === 0 ? (
            <span
              type="text"
              style={{
                width: "70px",
                height: "30px",
                color: "#d32f2f",
                background: "transparent",
                border: "none",
              }}
            >
              Deactivate
            </span>
          ) : (
            <span
              type="text"
              style={{
                width: "70px",
                height: "30px",
                color: "green",
                background: "transparent",
                border: "none",
              }}
            >
              Activate
            </span>
          )}
        </div>
      ),
    },
    {
      Header: "Actions",
      accessor: "actions", // Custom actions, not mapped to a specific field
      width: 170, // Specify width
      Cell: ({ row }) => (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {row.original.active === 1 ? (
            <button
              type="button"
              style={{
                width: "80px",
                height: "30px",
                color: "white",
                background: "#d32f2f",
                border: "none",
                borderRadius: "10px",
              }}
              onClick={() => HandleActivate(row.original)}
            >
              Deactivate
            </button>
          ) : (
            <button
              type="button"
              style={{
                width: "80px",
                height: "30px",
                color: "white",
                background: "green",
                border: "none",
                borderRadius: "10px",
              }}
              onClick={() => HandleActivate(row.original)}
            >
              Activate
            </button>
          )}

          {row.original.is_edit === 1 && (
            <button
              className="text-primary"
              type="button"
              onClick={() => {
                setEdit(true);
                setRowData(row.original);
              }}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                border: "none",
                background: "transparent",
              }}
            >
              <img
                src="/assets/images/edit.svg"
                alt="svg"
                style={{ marginRight: "0px" }}
              />
              <span className="ms-2">Edit</span>
            </button>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="mt-4" style={{ width: "100%", paddingInline: "0px" }}>
        <CreateRoleForm fetchCreateTblData={fetchCreateTblData} />

        <Box className="py-4">
          <NewGlobalTable
            method="GET"
            isSearchable={true}
            isCache={false}
            pageSize={50}
            apiEndPoint={"permission/get-role-with-permission"}
            keyAPiName="rolewithpermission"
            headerColumns={headerColumns}
            isTblUpdated={tableReset}
          />
        </Box>

        <UpdateRoleStatus
          updateModal={updateModal}
          setUpdateModal={setUpdateModal}
          roleId={roleId}
          active={active}
          fetchCreateTblData={fetchCreateTblData}
        />

        {edit && (
          <EditAssignCreateModal
            show={edit}
            setShow={setEdit}
            rowData={rowData}
            fetchCreateTblData={fetchCreateTblData}
          />
        )}
      </div>
    </>
  );
};

export default CreateRole;
