import React, { useState } from 'react';
import CustomTabBar from '../../../common/components/tabs';
import SiteDetails from './tabComponents/SiteDetails';
import ContactListing from './tabComponents/ContactListing';
import PopsListing from './tabComponents/PopsListing';
import EquipmentListing from './tabComponents/EquipmentListing/EquipmentListing';
import { DecryptToken, getPermission } from '../../../common/helper';
import SiteAeds from '../pages/SiteAeds';
import { useLocation, useNavigate, Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { handleTabfunction } from '../../../store/slices/TabSlice';
import { addNewBreadcrumbs, handleBreadcrumbsClick } from '../../../store/slices/breadcrumbsSlice';
import { AccountSiteTab, SiteTabList } from '../../../common/hooks';
import Support from '../../Support/Component/SupportTab/Support';

const SitesTab = ({
  accountId: siteAccountId,
  name,
  siteId,
  siteData,
  setSiteData,
  billingData,
  shippingData,
  trainingData,
  siteHoursData,
  aedUnits,
  coordinatorData,
  fetch,
  type,
  // tabTbldata,
  // setTabTbldata,
  setEditSiteAdmin,
  support_api,
  newSupportUrl
}) => {

  const navigate = useNavigate();
  // const [activeTab, setActiveTab] = useState('SITE_DETAILS');
  const breadcrumbsFromStore = useSelector(
    (state) => state.BreadCrumbs.breadcrumbs
  );

  const dispatch = useDispatch();
  const { tab } = useParams()
  const activeTab = tab || 'Details';
  const [showLoading, setShowLoading] = useState(true);

  const user = DecryptToken();
  const privilege = getPermission();
  const location = useLocation();

  const accountName = location?.state?.accountName;
  const accountId = location?.state?.accountId || siteAccountId;
  console.log({ location })

  const [tabTbldata, setTabTbldata] = useState({
    site: false,
    contact: false,
    equipment: {
      aed: false,
      accessory: false,
    },
    note: false,
    support: false,
  });

  // const list = [
  //   { title: "Details", id: "SITE_DETAILS" },
  //   { title: "Contacts", id: "CONTACT_LISTING" },
  //   { title: "Equipment", id: "EQUIPMENT_LISTING" },

  // ];

  const handleDispatch = (siteId, BreadCrumbsObject, val) => {

    dispatch(
      addNewBreadcrumbs({
        ...BreadCrumbsObject,
        url: `/account/site-details/${siteId}/${val}`,
      })
    );
  };

  /*
  
  AccountSiteTab
  
  */

  const componentsList = {
    Details: (
      <SiteDetails
        siteData={siteData}
        setSiteData={setSiteData}
        billingData={billingData}
        shippingData={shippingData}
        trainingData={trainingData}
        siteHoursData={siteHoursData}
        aedUnits={aedUnits}
        coordinatorData={coordinatorData}
        fetch={fetch}
        setEditSiteAdmin={setEditSiteAdmin}
      />
    ),

    Contacts: (
      <ContactListing
        accountId={accountId}
        siteId={siteId}
        tabTbldata={tabTbldata}
        setTabTbldata={setTabTbldata}
      />
    ),
    // )

    // EQUIPMENT_LISTING: <EquipmentListing
    // accountId={accountId}
    // siteId={siteId}
    // type={type}
    // accountName=""
    // tabTbldata={tabTbldata}
    // setTabTbldata={setTabTbldata}

    // />,


    Equipment: (<SiteAeds

      accountId={accountId}
      siteId={siteId}
      type={type}
      accountName={location?.state?.accountName}
      tabTbldata={tabTbldata}
      setTabTbldata={setTabTbldata}
    />),
    Support: (<Support
      newSupportUrl={newSupportUrl}
      support_api={support_api}
      account_id={siteData?.siteInfo?.account_id}
      setTabTbldata={setTabTbldata}
      fetch_data_key={'data'}
      stateData={{
        type: "site",
        site_id: siteId,
        aedId: 0,
        accountId: siteData?.siteInfo?.account_id || 0,
        contactId: 0,
        accountName: "",
        support_name: siteData?.siteInfo?.account_site_name,
      }}

    />)

    // POPS_LISTING: <PopsListing />,
  };

  const handleTabChange = (val, account_Name) => {
    console.log({ val, account_Name })
    const TabTitle = AccountSiteTab[val];
    const BreadCrumbsObject = {
      title: "",
      tab: "",
      type: ""
    }

    if (val === AccountSiteTab.Details) {
      BreadCrumbsObject.tab = val;

      dispatch(
        handleBreadcrumbsClick({
          accountName: account_Name,
          breadcrumbsFromStore,
          tab: val,
          type: "site",
        })
      );
      navigate(`/account/site-details/${siteId}/${val}`,)

    } else {
      BreadCrumbsObject.accountId = accountId;
      BreadCrumbsObject.account_Name = accountName
      BreadCrumbsObject.title = TabTitle;
      // BreadCrumbsObject.tab = val;
      // BreadCrumbsObject.type = "site";
      handleDispatch(siteId, BreadCrumbsObject, val);
      navigate(`/account/site-details/${siteId}/${val}`,)
    }
    // dispatch(handleTabfunction({ value: val, type: "site" }));
  };
  const arr = [];
  Object.keys(AccountSiteTab).forEach((key) => {
    arr.push({ id: key, title: AccountSiteTab[key] });
  });

  return (
    <>

      <div className="w-full">
        <CustomTabBar
          tabs={arr}
          onTabChange={(val) => handleTabChange(val, name)}
          activeTab={activeTab}
          from={"site"}
        />
        {componentsList?.[activeTab]}
      </div>
    </>
  );
};

export default SitesTab;
